import { render, staticRenderFns } from "./WelcomeText.vue?vue&type=template&id=53584f62&"
import script from "./WelcomeText.vue?vue&type=script&lang=js&"
export * from "./WelcomeText.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\zivan.pesic\\Documents\\esbn-front-vue-abbondanza\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('53584f62')) {
      api.createRecord('53584f62', component.options)
    } else {
      api.reload('53584f62', component.options)
    }
    module.hot.accept("./WelcomeText.vue?vue&type=template&id=53584f62&", function () {
      api.rerender('53584f62', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/categoryBlock/WelcomeText.vue"
export default component.exports