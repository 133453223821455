// eslint-disable-next-line no-unused-vars
const dummy = global.config; // Don't delete this dummy const, otherwise Webpack won't find global
const analyticsClass = global.config.analyticsClass || "analyticsServiceGA3";
const implementation = import(
  /* webpackChunkName: "analyticsServiceImplementation" */
  `~/service/analytics/${analyticsClass}.js`
);
// let imp = null;
// implementation.then(module => {
//   imp = module;
// });

const callImp = key => (...args) => {
  implementation.then(imp => {
    return imp.default[key](...args);
  });
};

export default {
  data() {
    return { inizialized: null };
  },
  setInitialized(value) {
    this.inizialized = value;
  },
  getInitialized() {
    return this.inizialized;
  },
  configureCustomMap: callImp("configureCustomMap"),
  initCustomDimension: callImp("initCustomDimension"),
  trackEvent: callImp("trackEvent"),
  purchase: callImp("purchase"),
  refund: callImp("refund"),
  viewCart: callImp("viewCart"),
  beginCheckout: callImp("beginCheckout"),
  checkoutProgress: callImp("checkoutProgress"),
  checkoutOptions: callImp("checkoutOptions"),
  addPaymentInfo: callImp("addPaymentInfo"),
  viewPromotions: callImp("viewPromotions"),
  clickPromotion: callImp("clickPromotion"),
  addToCart: callImp("addToCart"),
  removeFromCart: callImp("removeFromCart"),
  addProductToList: callImp("addProductToList"),
  removeProductToList: callImp("removeProductToList"),
  viewProducts: callImp("viewProducts"),
  viewProductDetail: callImp("viewProductDetail"),
  clickProduct: callImp("clickProduct"),
  selectWarehouse: callImp("selectWarehouse"),
  selectStore: callImp("selectStore"),
  selectAddress: callImp("selectAddress"),
  selectTimeslot: callImp("selectTimeslot"),
  login: callImp("login"),
  logout: callImp("logout"),
  signUp: callImp("signUp"),
  share: callImp("share"),
  search: callImp("search"),
  search_null: callImp("search_null"),
  cartEvent: callImp("cartEvent")
};
