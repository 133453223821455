<template functional>
  <div
    :class="
      `product-price d-flex justify-center ${
        props.product.warehousePromo
          ? props.product.warehousePromo.view.cssClass + ' product_promo'
          : ''
      }`
    "
  >
    <div
      v-if="props.product.warehousePromo"
      class="header"
      v-html="props.product.warehousePromo.view.header"
    ></div>
    <span
      class="max-purchasable smaller"
      v-if="props.product.productInfos.MAXACQ"
    >
      max {{ props.product.productInfos.MAXACQ }}
    </span>
    <div class="old-price" v-if="props.product.priceStandardDisplay">
      {{ parent.$n(props.product.priceStandardDisplay, "currency")
      }}<span
        v-if="props.product.productInfos.TIPOLOGIA != 'Pezzo'"
        class="unit-price"
        >/{{ parent.product.weightUnitDisplay }}
      </span>
    </div>
    <div class="d-flex justify-space-between align-end price-container">
      <div class="cur-price">
        {{ parent.$n(props.product.priceDisplay, "currency") }}
        <span
          v-if="props.product.productInfos.TIPOLOGIA != 'Pezzo'"
          class="unit-price"
          >/{{ parent.product.weightUnitDisplay }}
        </span>
      </div>
    </div>

    <div
      v-if="props.product.warehousePromo"
      class="footer"
      v-html="props.product.warehousePromo.view.footer"
    ></div>
    <div
      v-if="
        props.product.warehousePromo && props.product.warehousePromo.view.bubble
      "
      class="bubble"
      v-html="props.product.warehousePromo.view.bubble"
    ></div>
  </div>
</template>
<style lang="scss">
.product-price {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  height: 56px;
  margin-top: 4px;

  .card {
    position: absolute;
    bottom: 86px;
    left: 120px;

    img {
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.85);
      width: 46px;
      margin-bottom: 3px;
    }
  }

  .max-purchasable {
    display: none;
  }
  .cur-price {
    text-align: left;
    font: normal normal 600 30px/36px $body-font-family;
    letter-spacing: 0px;
    white-space: nowrap;
    padding: 0 6px;
    height: 35px;
    color: $primary;
    .unit-price {
      font-size: 16px;
      text-transform: lowercase;
    }
  }
  .old-price {
    font: normal normal 500 16px/17px $body-font-family;
    letter-spacing: 0px;
    text-decoration: line-through;
    white-space: nowrap;
    padding: 4px 6px 0 6px;
    .unit-price {
      font-size: 10px;
      text-transform: lowercase;
    }
  }
  &.product_promo {
    // background: #fdcc00;
    // border-radius: 4px;
    .header {
      width: 100%;
      text-align: center;
      color: #ee1d23;
      font: 700 11px/18px $body-font-family !important;
      text-transform: uppercase;
    }
    .bubble {
      text-align: center;
      position: absolute;
      background: #fdcc00;
      top: 6px;
      left: 5px;
      border-radius: 4px;
      width: 71px;
      height: 47px;
      p {
        color: #ee1d23;
        font-size: 12px;
        margin: 0px;
        padding: 4px 4px 2px 4px;
        line-height: 1;
        font-weight: bold;
        text-transform: uppercase;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
      strong {
        color: #ee1d23;
        font-size: 26px;
        display: block;
        letter-spacing: -2px;
        height: 31px;
        line-height: 31px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
      .card {
        position: absolute;
        bottom: -16px;
        right: -11px;
        margin-left: -5px;
        left: initial;
        img {
          width: 26px;
          box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.85);
        }
      }
    }

    &.promo_ottienipunti {
      .bubble {
        background-color: #f58220;
        p {
          color: #ffffff;
        }
        strong {
          color: #ffffff;
          height: 12px;
          line-height: 12px;
        }
      }
    }
    &.promo_percent {
      .bubble {
        strong {
          background-color: red;
          color: #fff;
        }
      }
    }

    &.promo_al_costo {
      .header {
        background-color: #fff;

        span {
          padding: 0 2px;
          font-size: 11px;
          font-weight: 900;
          display: inline-block;
          text-transform: uppercase;
        }
        span.first {
          border: 1px solid #cf282c;
          color: #cf282c;
          transform: skew(0, -10deg);
          background-color: #fff;
        }
        span.second {
          color: #cf282c;
          transform: skew(0, -10deg);
        }
        span.third {
          //border: 2px solid #fff;
          color: #fff;
          transform: skew(0, -10deg);
          background-color: #cf282c;
        }

        @media (max-width: 410px) {
          span {
            font-size: 9px;
          }
        }
      }
    }
    &.promo_sotto_freschi {
      .header {
        background-color: #fff;

        span {
          padding: 0 2px;
          font-size: 11px;
          font-weight: 900;
          display: inline-block;
          text-transform: uppercase;
        }
        span.first {
          border: 1px solid #cf282c;
          color: #cf282c;
          transform: skew(0, -10deg);
          background-color: #fff;
        }
        span.second {
          color: #cf282c;
          transform: skew(0, -10deg);
        }
        span.third {
          //border: 2px solid #fff;
          color: #fff;
          transform: skew(0, -10deg);
          background-color: #cf282c;
        }

        @media (max-width: 410px) {
          span {
            font-size: 9px;
          }
        }
      }
    }
  }

  &.product_promo.promo_costo_freschi {
    .header {
      background-color: #fff;

      span {
        padding: 0 2px;
        font-size: 11px;
        font-weight: 900;
        display: inline-block;
        text-transform: uppercase;
      }
      span.first {
        border: 1px solid #cf282c;
        color: #cf282c;
        transform: skew(0, -10deg);
        background-color: #fff;
      }
      span.second {
        color: #cf282c;
        transform: skew(0, -10deg);
      }
      span.third {
        //border: 2px solid #fff;
        color: #fff;
        transform: skew(0, -10deg);
        background-color: #cf282c;
      }
      @media (max-width: 410px) {
        span {
          font-size: 9px;
        }
      }
    }
  }
}
.is-cordova {
  .price-wraper {
    justify-content: flex-start !important;
  }
  .product-price {
    width: unset !important;
  }
  .product-card {
    .iva {
      margin-bottom: 2px;
    }
  }
}
.horizontal-crm .product_promo .bubble {
  top: 0px;
  left: -68px;
  width: 65px;
  right: initial;
}
</style>
<script>
export default {
  name: "props.productPrice",
  props: {
    product: { type: Object, required: true }
  }
};
</script>
