<template>
  <v-card flat elevation="0" class="product-option-selector pa-3 pa-sm-6">
    <v-card-title>
      <template v-if="$te('productPromoSelector.title')">
        {{ $t("productPromoSelector.title") }}
      </template>
      <template v-else>
        Vuoi applicare questa promozione?
      </template>
    </v-card-title>
    <div class="d-flex justify-center">
      <product-promo :warehousePromo="warehousePromo" />
    </div>

    <div
      v-if="
        warehousePromo &&
          warehousePromo.view &&
          warehousePromo.view.selectablePromoText
      "
      v-html="warehousePromo.view.selectablePromoText"
      class="selectable-promo-descr"
    ></div>
    <div
      v-else-if="$te('productPromoSelector.description')"
      class="selectable-promo-descr"
    >
      {{ $t("productPromoSelector.description") }}
    </div>
    <div v-else class="selectable-promo-descr">
      Seleziona "Sì" se vuoi acquistare il prodotto vicino alla scadenza a
      prezzo scontato
    </div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="primary" text @click="close">
        No
      </v-btn>
      <v-btn class="primary" @click="apply">
        Sì
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import ProductPromo from "@/components/product/ProductPromo.vue";
export default {
  components: { ProductPromo },
  name: "ProductPromoSelector",
  data() {
    return {
      selectedOptions: {},
      optionsArray: []
    };
  },
  props: {
    warehousePromo: { type: Object, required: true }
  },
  methods: {
    close() {
      this.$emit("submit", false);
    },
    apply() {
      this.$emit("submit", this.warehousePromo.warehousePromoId);
    }
  }
};
</script>
