<template>
  <div class="number-counter d-flex justify-center align-center">
    <div
      v-for="(digit, index) in currentValue"
      :key="index"
      class="digit d-flex align-center justify-center ma-1 pa-3 text-center font-weight-bold rounded-md"
    >
      {{ digit }}
    </div>
  </div>
</template>
<style scoped lang="scss">
.number-counter {
  .digit {
    border: 3px var(--v-green-base) solid;
    font-size: 2.5rem;
    width: 80px;
    height: 80px;
    max-width: 100%;
  }
}
</style>
<script>
export default {
  name: "NumberCounter",
  props: {
    num: { type: String, required: true },
    step: { type: Number, default: 50 },
    speed: { type: Number, default: 5000 }
  },
  data() {
    return {
      currentValue: "",
      targetValue: ""
    };
  },
  mounted() {
    this.num = this.num.padStart(this.num.length, "0"); // assign padded value to num prop
    this.currentValue = "0".repeat(this.num.length); // initialize currentValue
    this.animateValue();
  },
  methods: {
    animateValue() {
      const targetValue = (this.num = this.num.padStart(this.num.length, "0"));
      let current = parseInt(this.currentValue);
      const target = parseInt(targetValue);
      const increment = Math.ceil(
        (target - current) / (this.speed / this.step)
      );

      const interval = setInterval(() => {
        if (current >= target) {
          clearInterval(interval);
          return;
        }

        current += increment;

        // Check if current value exceeds target value
        if (current >= target) {
          current = target;
        }

        this.currentValue = current.toString().padStart(this.num.length, "0");
      }, this.step);
    }
  }
};
</script>
