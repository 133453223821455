<template>
  <div
    class="preferred-store-card d-flex align-center"
    v-ripple
    @click="
      selectedStore
        ? $router.push({ path: '/punti-vendita/' + selectedStore })
        : $router.push({ path: '/punti-vendita' })
    "
  >
    <div class="logo-img">
      <img class="logo" src="/logo/logo_mobile.png" alt="Logo" />
    </div>
    <div class="flex-grow-1 d-flex flex-column">
      <div v-if="warehouse">
        <div v-if="!showOpenTime" class="text-uppercase default--text">
          {{ $t("leaflet.preferredShop") }}
        </div>
        <div class="store-name primary--text font-weight-semibold text-body-0">
          {{ warehouse.name }} {{ warehouse.address.city }}
        </div>
        <div v-if="showOpenTime">
          <span class="primary--text font-weight-semibold"
            >{{
              calculateTime.open ? $t("warehouse.open") : $t("warehouse.closed")
            }} </span
          ><span v-html="calculateTime.message"></span>
        </div>
      </div>
      <div
        v-else-if="!loading"
        class="store-name primary--text font-weight-semibold text-body-0"
      >
        {{ $t("leaflet.noPreferredShop") }}
      </div>
    </div>
    <div v-if="selectedStore">
      <v-icon class="default--text">$chevronRight</v-icon>
    </div>
  </div>
</template>
<style lang="scss">
.preferred-store-card {
  padding: 10px;
  margin-top: 10px;
  height: 70px;
  border-radius: 8px;
  background-color: var(--v-primary-lighten1);
  cursor: pointer;
  .logo-img {
    height: 100%;
    padding: 6px 0;
    img {
      height: 100%;
    }
  }
  .store-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
</style>
<script>
import AddressService from "@/commons/service/addressService";
import {
  calculateHourRow,
  isOpenRightNow,
  isOpenRightNowMessage
} from "@/warehouseHoursHelper";
import { mapGetters, mapState } from "vuex";
export default {
  name: "SelectedStore",
  props: { showOpenTime: { type: Boolean, default: false } },
  data() {
    return {
      warehouse: null,
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    }),
    ...mapState({
      selectedStoreState: ({ custom }) => custom.selectedStoreNoAccount
    }),
    selectedStore() {
      if (this.user && this.user.userId) {
        const pdvAsInt = parseInt(this.user.pdv);
        if (!isNaN(pdvAsInt)) {
          return pdvAsInt;
        } else {
          return null;
        }
      } else {
        return this.selectedStoreState;
      }
    },
    calculateTime() {
      let today = this.$dayjs();
      let todayH = calculateHourRow(
        this.warehouse?.serviceHours,
        today,
        this.offsetDay
      );
      let isOpen = isOpenRightNow(this.warehouse?.serviceHours, this.offsetDay);
      let message = isOpenRightNowMessage(
        this.warehouse?.serviceHours,
        this.offsetDay
      );
      return {
        hours: todayH ? todayH : "",
        open: isOpen,
        message: message
      };
    }
  },
  methods: {
    getWarehouse() {
      if (this.selectedStore) {
        this.loading = true;
        AddressService.getWarehouseById(this.selectedStore).then(data => {
          this.warehouse = data;
          this.loading = false;
        });
      }
    }
  },
  mounted() {
    this.getWarehouse();
  }
};
</script>
