<template>
  <div
    v-if="products.length > 0 || welcomeProducts.length > 0"
    :id="`discount-list-slider-${config.categoryBlockId}`"
    class="discount-list-slider"
  >
    <TitleRow
      :config="config"
      categoryBlockName="DiscountsSlider"
      :showMore="true"
    />
    <ebsn-meta
      :target="config"
      :path="`categoryblocktype_DiscountsSlider.DESCRIPTION`"
      tag="div"
    />
    <div class="slider discount-slider mx-n2">
      <swiper :options="swiperOption" ref="swiperRef">
        <swiper-slide v-for="product in products" :key="product.productId">
          <CouponCard
            :coupon="product"
            :type="'discount'"
            class="my-1 mx-2"
            :userPoints="userPointsInt"
            @showDetails="showDetails"
          />
        </swiper-slide>
        <swiper-slide
          v-for="product in welcomeProducts"
          :key="product.productId"
        >
          <CouponCard
            :coupon="product"
            class="my-1 mx-2"
            :title="'coupons.discounts.welcome'"
            :type="'welcomeCoupon'"
            @showDetails="showActiveCoupon"
          />
        </swiper-slide>
      </swiper>
      <div
        v-if="
          $ebsn.meta(
            config,
            'categoryblocktype_DiscountsSlider.SHOW_BULLETS',
            true
          ) &&
            (products.length > 0 || welcomeProducts.length > 0)
        "
        :id="`discount-list-pagination-${config.categoryBlockId}`"
        :class="
          `swiper-pagination discount-list-pagination-${config.categoryBlockId}`
        "
      ></div>
      <div v-else class="py-4"></div>
      <div
        v-if="
          $ebsn.meta(
            config,
            'categoryblocktype_DiscountsSlider.SHOW_ARROWS',
            false
          ) &&
            (products.length > 0 || welcomeProducts.length > 0)
        "
        :id="`discount-list-slider-prev-${config.categoryBlockId}`"
        class="swiper-button-prev"
      ></div>
      <div
        v-if="
          $ebsn.meta(
            config,
            'categoryblocktype_DiscountsSlider.SHOW_ARROWS',
            false
          ) &&
            (products.length > 0 || welcomeProducts.length > 0)
        "
        :id="`discount-list-slider-next-${config.categoryBlockId}`"
        class="swiper-button-next"
      ></div>
    </div>
  </div>
</template>
<style lang="scss">
.show-more {
  .v-btn {
    margin-right: -4px;
  }
}
.discount-slider {
  .proposal-img {
    margin-bottom: 10px;
  }
  .banner-card {
    width: 100%;
    margin-bottom: 20px;
  }
  .proposal-card {
    width: 220px;
    height: 380px;
    .v-card__text {
      width: auto;
    }
    img {
      padding: 0px;
      align-self: center;
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .product-card {
      .v-image {
        min-height: 110px;
      }
    }
    .proposal-card {
      height: 285px;
      width: 170px;
      .v-card__text {
        font-size: 11px;
        padding: 0 5px;
      }
    }
  }
  .firstCard {
    float: left;
  }
}
</style>
<script>
import CouponCard from "@/components/coupon/CouponCard.vue";
import CouponDetail from "@/components/coupon/CouponDetail.vue";
import ActiveCouponDetail from "@/components/coupon/ActiveCouponDetail.vue";
import deliveryReactive from "~/mixins/deliveryReactive";
import categoryBlockType from "./categoryBlockType";

import CopuonService from "@/service/couponsService";
import TitleRow from "./elements/TitleRow.vue";
import { mapGetters } from "vuex";

export default {
  name: "DiscountsSlider",
  props: {
    config: {
      type: Object,
      required: true
    },
    container: {
      type: Boolean,
      default: true
    },
    productId: {
      type: Number,
      required: false
    },
    categoryId: {
      type: Number,
      required: false
    }
  },
  mixins: [deliveryReactive, categoryBlockType],
  components: {
    CouponCard,
    TitleRow
  },
  data() {
    return {
      products: [],
      welcomeProducts: [],
      swiperDefaultOption: {
        slidesPerGroup: 1,
        spaceBetween: 20,
        watchOverflow: false,
        autoplay: false,
        pagination: {
          el: `#discount-list-pagination-${this.config.categoryBlockId}`,
          clickable: true
        },
        navigation: {
          prevEl: `#discount-list-slider-prev-${this.config.categoryBlockId}`,
          nextEl: `#discount-list-slider-next-${this.config.categoryBlockId}`
        },
        breakpoints: {
          0: {
            slidesPerView: 1
          },
          600: {
            slidesPerView: 2
          },
          960: {
            slidesPerView: 3
          },
          1264: {
            slidesPerView: 4
          },
          1900: {
            slidesPerView: 6
          }
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      userPoints: "custom/userPoints",
      isAuthenticated: "cart/isAuthenticated"
    }),
    userPointsInt() {
      return parseInt(this.userPoints);
    }
  },
  methods: {
    async reload() {
      this.products = [];

      // let params = {
      //   layout: algorithm,
      //   limit: Vue.$ebsn.meta(
      //     this.config,
      //     "categoryblocktype_CrmProductSlider.PRODUCT_LIMIT",
      //     12
      //   )
      // };

      let response = await CopuonService.getCouponsByCategoryId(34661);
      if (response.products) {
        this.products = response.products;
      }
      if (this.isAuthenticated) {
        await CopuonService.getActivatedDiscountsByType("welcome").then(
          data => {
            this.welcomeProducts = data.values;
          }
        );
      }
      if (
        (!this.products || this.products.length < 1) &&
        (!this.welcomeProducts || this.welcomeProducts.length < 1)
      ) {
        this.$emit("hide");
      }
    },
    async showDetails(coupon, type) {
      if (!this.$route.hash) {
        this.$router.push("/#");
      }
      let res = await this.$dialog.show(CouponDetail, {
        waitForResult: true,
        fullscreen: this.$vuetify.breakpoint.xsOnly,
        couponSlug: coupon.slug,
        userPoints: this.userPointsInt,
        type: type,
        width: 500
      });
      if (res) {
        console.log(res);
      }
      if (this.$route.hash) {
        this.$router.go(-1);
      }
    },
    async showActiveCoupon(coupon, type, style = "") {
      if (!this.$route.hash) {
        this.$router.push("/#");
      }
      let res = await this.$dialog.show(ActiveCouponDetail, {
        waitForResult: true,
        fullscreen: this.$vuetify.breakpoint.xsOnly,
        coupon: coupon,
        type: type,
        customClass: style,
        width: 500
      });
      if (res) {
        console.log(res);
      }
    }
  },
  mounted() {
    this.reload();
  }
};
</script>
