<template>
  <div class="customer-data">
    <div class="text-uppercase font-weight-semibold px-2 mt-6">
      {{ $t("profile.header.personalData") }}
    </div>
    <div class="mb-2 text-body-3 px-2">
      * {{ $t("profile.requiredFields") }}
    </div>
    <v-form v-model="isValid" ref="form" @submit.prevent>
      <v-row no-gutters class="px-2">
        <v-col cols="12" md="6" v-if="userData.fidelity">
          <v-text-field
            class="mt-2"
            v-model="userData.fidelity"
            :label="`${$t('profile.cartaFedeltà')}`"
            outlined
            rounded
            dense
            disabled
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="5">
          <v-text-field
            :label="`${$t('register.card.step-0.placeholder.surname')} *`"
            :rules="[requiredRules()]"
            class="mt-2"
            required
            outlined
            rounded
            dense
            type="text"
            v-model="userData.lastName"
            :disabled="!!userData.fidelity"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :label="`${$t('register.card.step-0.placeholder.name')} *`"
            :rules="[requiredRules()]"
            class="mt-2"
            required
            outlined
            rounded
            dense
            type="text"
            v-model="userData.firstName"
            :disabled="!!userData.fidelity"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :label="`${$t('profile.address.address')} *`"
            :rules="[requiredRules()]"
            class="mt-2"
            required
            outlined
            rounded
            dense
            type="text"
            v-model="userData.address"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :label="`${$t('profile.address.addressNumber')} *`"
            :rules="[requiredRules()]"
            class="mt-2"
            required
            outlined
            rounded
            dense
            type="text"
            v-model="userData.addressNumber"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            ref="cap"
            :label="`${$t('profile.address.postalcode')} *`"
            :rules="capRules"
            class="mt-2"
            required
            outlined
            rounded
            dense
            type="text"
            v-model="userData.cap"
            @input="getCities"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-if="hasData"
            :label="`${$t('profile.address.city')} *`"
            :rules="[requiredRules()]"
            class="mt-2"
            required
            outlined
            rounded
            dense
            type="text"
            disabled
            v-model="userData.city"
            :loading="loadingCities"
          />
          <v-select
            v-else
            ref="selectedCity"
            class="mt-2"
            v-model="selectedCity"
            :items="cityList"
            item-text="city"
            return-object
            :label="`${$t('profile.address.city')} *`"
            outlined
            rounded
            dense
            :disabled="isFieldDisabled || !showCityInput"
            :rules="[requiredRules()]"
            @change="citySelected"
            :no-data-text="$t('profile.address.noCityFoundForCap')"
            :loading="loadingCities"
          ></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            ref="province"
            :label="`${$t('profile.address.province')} *`"
            :rules="[requiredRules()]"
            class="mt-2"
            required
            outlined
            rounded
            dense
            type="text"
            v-model="userData.prov"
            disabled
          />
        </v-col>
        <!--v-col cols="12" md="6">
          <v-select
            class="mt-2"
            v-model="storeToSelect"
            :items="storeList"
            item-text="address.city"
            item-value="warehouseId"
            :label="`${$t('profile.selectedStore')}`"
            outlined
            rounded
            dense
            :rules="[requiredRules()]"
          ></v-select>
        </v-col-->
        <!-- <v-col cols="12" md="6">
          <v-dialog
            v-model="birthDateCal"
            transition="scale-transition"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mt-2"
                :value="birthDate"
                append-icon="$calendar"
                @click:append="birthDateCal = true"
                :label="`${$t('profile.birthDate')} *`"
                readonly
                outlined
                rounded
                dense
                v-bind="attrs"
                v-on="on"
                :rules="[requiredRules()]"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="userData.birthDate"
              :max="maxBirthDate"
              :show-current="pickerDate"
              locale="it-IT"
              no-title
              color="primary"
              format="YYYY-MM-DD"
              @change="birthDateCal = false"
            ></v-date-picker>
          </v-dialog>
        </v-col> -->
        <!-- <v-col cols="12" md="6">
          <v-select
            class="mt-2"
            v-model="userData.sex"
            :items="genders"
            item-text="label"
            item-value="code"
            :label="`${$t('profile.gender')} *`"
            outlined
            rounded
            dense
            :disabled="isFieldDisabled"
            :rules="[requiredRules()]"
          ></v-select>
        </v-col> -->
        <v-col cols="12" md="6">
          <v-text-field
            class="mt-2"
            v-model="userData.phone"
            :rules="phoneRules"
            :label="`${$t('profile.contacts.mobilePhone')} *`"
            outlined
            rounded
            dense
            :disabled="isFieldDisabled"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="my-3"
          ><div class="text-uppercase font-weight-semibold">
            {{ $t("profile.header.loginData") }}
          </div></v-col
        >
        <v-col cols="12" md="6">
          <!-- new email -->
          <v-text-field
            class="mt-2"
            v-model="userData.email"
            :label="`${$t('profile.email.newEmail')} *`"
            :rules="emailRules"
            required
            outlined
            rounded
            dense
          />
        </v-col>
        <v-col cols="12" md="6">
          <!-- confirm email -->
          <v-text-field
            class="mt-2"
            v-model="emailConfirm"
            :label="`${$t('profile.email.emailConfirm')}`"
            :hint="$t('register.card.step-0.placeholder.emailHint')"
            persistent-hint
            :rules="emailConfirmRules"
            outlined
            rounded
            dense
            @paste.prevent
          />
        </v-col>
        <v-col cols="12" sm="6">
          <!-- password -->
          <v-text-field
            class="mt-2"
            v-model="userData.password"
            :label="`${$t('profile.password')} *`"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            required
            outlined
            dense
            rounded
          />
        </v-col>
        <v-col cols="12" sm="6">
          <!-- conferma password -->
          <v-text-field
            class="mt-2"
            :label="`${$t('profile.passwordConfirm')} *`"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordConfirmRules"
            required
            outlined
            dense
            rounded
            @paste.prevent
          />
        </v-col>
        <v-col cols="12" class="my-3"
          ><h5 class="text-uppercase">
            {{ $t("profile.disclaimerTitle") }}
          </h5>
          <!-- <p
            class="pre-wrap mt-2 text-body-2"
            v-html="$t('profile.disclaimerSubtitle')"
          ></p> -->
          <i18n
            v-if="userData.fidelity"
            path="profile.disclaimerSubtitle"
            tag="p"
            class="pre-wrap mt-2 text-body-2"
          >
            <template v-slot:link1>
              <span
                class="text-decoration-underline"
                @click.prevent.stop="
                  handleRedirect('https://www.galasupermercati.it/privacy')
                "
                >{{ $t("profile.disclaimerSubtitleLink") }}</span
              >
            </template>
            <template v-slot:link2>
              <span
                class="text-decoration-underline"
                @click.prevent.stop="
                  handleRedirect(
                    'https://www.galasupermercati.it/informativa-carta-fedelta'
                  )
                "
                >{{ $t("profile.disclaimerSubtitleLink2") }}</span
              >
            </template> </i18n
          ><v-checkbox
            v-else
            :rules="checkboxRules"
            v-model="disclaimerMain"
            class="disclaimer-checkbox"
          >
            <template v-slot:label>
              <i18n
                path="profile.disclaimerMain"
                tag="span"
                class="pre-wrap mt-2 text-body-2"
              >
                <template v-slot:link1>
                  <span
                    class="text-decoration-underline"
                    @click.prevent.stop="
                      handleRedirect(
                        'https://www.galasupermercati.it/informativa-carta-fedelta'
                      )
                    "
                    >{{ $t("profile.disclaimerMainLink") }}</span
                  >
                </template>
                <template v-slot:link2>
                  <span
                    class="text-decoration-underline"
                    @click.prevent.stop="
                      handleRedirect(
                        'https://www.galasupermercati.it/regolamento-carta-fedelta'
                      )
                    "
                    >{{ $t("profile.disclaimerMainLink2") }}</span
                  >
                </template>
              </i18n>
            </template>
          </v-checkbox></v-col
        >
      </v-row>
    </v-form>
  </div>
</template>
<style lang="scss">
.customer-data {
  width: 100%;
  .disclaimer-label {
    color: var(--v-default-base);
  }
  .v-input .v-progress-linear {
    left: 15px;
    width: calc(100% - 30px);
  }
}
</style>
<script>
import AddressService from "~/service/addressService";
import {
  requiredValue,
  isMobilePhone,
  isEmail
} from "~/validator/validationRules";
import { isCheckboxTrueBool, isCAP } from "@/customValidationRules";
import CustomService from "@/service/customService";

export default {
  components: {},
  name: "NewCustomer",
  props: {
    isFieldDisabled: { type: Boolean, default: false },
    userData: { type: Object, required: false },
    response: { type: Object }
  },
  data() {
    return {
      isValid: false,
      emailConfirm: null,
      birthDateCal: false,
      //birthDate: null,
      showPassword: false,
      storeList: [],
      cityList: [],
      selectedCity: {},
      showCityInput: false,
      loadingCities: false,
      hasData: false,
      disclaimerMain: false,
      genders: [
        { code: "M", label: "Maschile" },
        { code: "F", label: "Femminile" }
      ],
      requiredRules: requiredValue,
      emailRules: [requiredValue(), isEmail()],
      emailConfirmRules: [
        v => !!v || "Confermare l'e-mail",
        v => v === this.userData.email || "L'e-mail non coincidono"
      ],
      passwordRules: [requiredValue("Digitare la password")],
      passwordConfirmRules: [
        v => !!v || "Confermare la password",
        v => v === this.userData.password || "Le password non coincidono"
      ],
      phoneRules: [requiredValue(), isMobilePhone()],
      checkboxRules: [
        isCheckboxTrueBool(
          "È obbligatorio accettare questo consenso per procedere"
        )
      ],
      capRules: [requiredValue(), isCAP()],
      pickerDate: this.$dayjs()
        .subtract(18, "years")
        .format("YYYY-MM-DD")
    };
  },
  methods: {
    validate() {
      let _this = this;
      setTimeout(function() {
        if (_this.$refs.form) {
          _this.valid = _this.$refs.form.validate();
          _this.$emit("validate", _this.valid);
        }
      }, 200);
    },
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    handleRedirect(url) {
      if (this.$platform_is_cordova && url.indexOf("http") > -1) {
        window.cordova.InAppBrowser.open(url, "_system");
      } else {
        window.open(url, "_blank");
      }
    },
    async getStoreList() {
      let data = await AddressService.findWarehouse();
      this.storeList = data.warehouses;
    },
    async getCities() {
      if (this.$refs["cap"].validate()) {
        this.loadingCities = true;
        let data = await CustomService.searchCitiesFromCap(this.userData.cap);
        this.loadingCities = false;
        this.cityList = data.data?.values;
        this.showCityInput = true;
        this.$nextTick(() => {
          const inputElement = this.$refs.selectedCity.$refs.input;
          inputElement.focus();
          this.$refs.selectedCity.activateMenu();
        });
      } else {
        this.cityList = [];
        this.userData.city = null;
        this.userData.prov = null;
        this.$refs["selectedCity"]?.reset();
        this.$refs["province"].reset();
        this.showCityInput = false;
        this.hasData = false;
      }
    },
    citySelected(data) {
      this.userData.city = data.city;
      this.userData.prov = data.province;
    }
  },
  computed: {
    maxBirthDate() {
      return this.$dayjs()
        .subtract(18, "years")
        .format("YYYY-MM-DD");
    },
    birthDate: {
      get: function() {
        if (this.userData.birthDate) {
          return this.$dayjs(this.userData.birthDate).format("DD/MM/YYYY");
        } else {
          return null;
        }
      },
      set: function(value) {
        this.userData.birthDate = this.$dayjs(value, "DD/MM/YYYY").format(
          "DD/MM/YYYY"
        );
      }
    },
    storeToSelect: {
      get: function() {
        const pdvAsInt = parseInt(this.userData.pdv);
        if (!isNaN(pdvAsInt)) {
          return pdvAsInt;
        } else {
          return null;
        }
      },
      set: function(value) {
        this.userData.pdv = value.toString();
      }
    }
  },
  created() {
    this.getStoreList();
    if (Object.keys(this.userData).length > 0) {
      this.hasData = true;
    }
  }
};
</script>
