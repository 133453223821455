<template>
  <v-container fluid fill-height class="pb-0 pt-12 px-0">
    <v-layout>
      <v-flex
        xs12
        class="d-flex flex-column align-center justify-space-between"
      >
        <div
          class="d-flex flex-column align-center justify-space-between w-100"
        >
          <img src="/logo/logo.png" alt="Logo" :style="{ height: '70px' }" />
          <v-form
            ref="form"
            class="w-100"
            v-model="valid"
            @submit.prevent.stop="handleSubmit"
          >
            <v-card class="elevation-0">
              <h2 class="default--text text-center mt-6 mb-0">
                {{ $t("login.title") }}
              </h2>
              <p class="mb-0 text-center px-4">
                {{ $t("login.subtitle") }}
              </p>
              <v-spacer></v-spacer>
              <v-card-text>
                <v-text-field
                  color="primary"
                  v-model="email"
                  :error-messages="errors"
                  type="email"
                  :label="$t('login.email')"
                  autocomplete="off"
                  :rules="emailRules"
                  required
                  dense
                  outlined
                  rounded
                  clearable
                ></v-text-field>
                <v-text-field
                  color="primary"
                  v-model="password"
                  :label="$t('login.password')"
                  autocomplete="off"
                  :append-icon="showPassword ? '$eye' : '$eyeOff'"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="passwordRules"
                  @click:append="toggleShowPassword"
                  required
                  dense
                  outlined
                  rounded
                  clearable
                ></v-text-field>

                <v-btn
                  :loading="loading"
                  type="submit"
                  color="primary"
                  large
                  block
                  depressed
                  rounded
                >
                  {{ $t("login.buttonLogin") }}
                </v-btn>

                <v-row no-gutters align="center" justify="end" class="mb-6">
                  <a
                    @click="openResetPasswordDialog"
                    class="forgot-password text-decoration-underline text-caption default--text mt-6"
                  >
                    {{ $t("login.forgotPassword") }}
                  </a>
                  <!-- </div> -->
                </v-row>

                <ResponseMessage :response="response" class="mt-3" />
                <!-- <v-btn
                    v-if="isCordova"
                    :loading="loadingCardCode"
                    class="mt-0 mb-1"
                    color="secondary"
                    large
                    block
                    depressed
                    @click="scanCard()"
                  >
                    {{ $t("login.buttonScanCard") }}
                  </v-btn> -->
                <!-- </div> -->
              </v-card-text>
            </v-card>
          </v-form>
        </div>
        <div
          v-if="enableRegistration"
          class="no-account-section d-flex secondary flex-column default--text pa-4 pb-12 w-100 white--text"
        >
          <h4 class="mb-0">
            {{ $t("login.messageRegister") }}
          </h4>
          <p class="px-5 font-weight-light">
            {{ $t("login.messageRegisterInfo") }}
          </p>
          <v-btn
            @click="openRegistrationDialog"
            large
            block
            depressed
            rounded
            color="white"
          >
            {{ $t("login.buttonRegister") }}
          </v-btn>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<style lang="scss" scoped>
.no-account-section {
  border-radius: 25px 25px 0px 0px;
  text-align: center;
}
.forgot-password {
  font-size: 14px !important;
}
</style>

<script>
import ResponseMessage from "@/components/ResponseMessage";
import RegistrationUser from "@/views/profile/RegistrationUser.vue";

import pushNotification from "~/mixins/pushNotification";

import { mapActions, mapGetters } from "vuex";

import SocialService from "~/service/socialService";
import resetPassword from "@/mixins/resetPassword";

import {
  requiredValue,
  isEmail,
  isPasswordMinimumLength
} from "~/validator/validationRules";
import CardNotActive from "@/components/profile/CardNotActive.vue";
import AbbondanzaRegistrationService from "@/service/abbondanzaRegistrationService";

export default {
  name: "Login",
  props: {
    hideLogo: { type: Boolean, required: false, default: false },
    hideRegistration: { type: Boolean, required: false, default: false },
    redirectParams: { type: Object, required: false }
  },
  mixins: [pushNotification, resetPassword],
  components: { ResponseMessage },
  data() {
    return {
      loading: null,
      loadingCardCode: null,
      showPassword: false,
      email: "",
      password: "",
      rememberMe: true,
      response: {},
      valid: true,
      lazy: true,
      emailRules: [requiredValue("Digita la tua email"), isEmail()],
      passwordRules: [
        requiredValue("Digita la tua password"),
        isPasswordMinimumLength()
      ],
      errors: [],
      invalid: null,
      enableRegistration: process.env.VUE_APP_ENABLE_REGISTRATION == "true"
    };
  },
  methods: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapActions({
      doLogin: "cart/doLogin",
      loadCart: "cart/loadCart"
    }),
    goToResetPassword() {
      this.$router.push({
        name: "ResetPasswordRequest"
      });
      this.$emit("submit", false);
    },
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    doFBLogin() {
      var _this = this;
      window.facebookConnectPlugin.login(
        ["public_profile", "email"],
        function(userData) {
          SocialService.facebookLoginConnect(
            userData.authResponse.accessToken,
            true
          ).then(
            function(data) {
              if (data.user.login && data.user.login != "") {
                if (data.data.socialRedirectUrl) {
                  data.data.socialRedirectUrl = "/dashboard";
                }
                _this.$emit("submit", false);
                global.vm.$router
                  .push({
                    path: data.data.socialRedirectUrl
                  })
                  .catch(err => err);
              } else {
                //NUOVO UTENTE
                if (data.data.socialRedirectUrl) {
                  data.data.socialRedirectUrl =
                    "/registration-nocard?social_login=facebook";
                }
                _this.$emit("submit", false);
                global.vm.$router
                  .push({
                    path: data.data.socialRedirectUrl
                  })
                  .catch(err => err);
                // SecurityService.refreshLogin(
                //   data.socialRedirectUrl.indexOf("registration") >= 0
                // ).then(
                //   function() {
                //     this.closeLogin();
                //   },
                //   function() {
                //     this.closeLogin();
                //   }
                // );
              }
            },
            function(error) {
              console.log(error);
            }
          );
        },
        function(error) {
          console.log(error);
        }
      );
    },
    facebookLogin() {
      if (this.isCordova) {
        this.doFBLogin();
      } else {
        let redirectBaseUrl = `${window.location.protocol}//${
          window.location.hostname
        }${
          window.location.port !== "80" &&
          window.location.port !== "443" &&
          window.location.port &&
          window.location.port !== ""
            ? ":" + window.location.port
            : ""
        }`;
        SocialService.openFacebookLoginPage(redirectBaseUrl);
      }
    },
    socialLoginGoogle() {
      if (this.isCordova) {
        let redirectBaseUrl =
          window.location.protocol +
          "//" +
          window.location.hostname +
          (window.location.port !== "80" &&
          window.location.port !== "443" &&
          window.location.port &&
          window.location.port !== ""
            ? ":" + window.location.port
            : "");

        let url =
          "https://accounts.google.com/o/oauth2/auth?client_id=" +
          global.config.googleClientId +
          "&redirect_uri=" +
          redirectBaseUrl +
          "/ebsn/api/social-redir/google&prompt=consent&response_type=code&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile";

        SocialService.handleGoogleOnApp(url, "registration-nocard");
      } else {
        SocialService.openGoogleLoginPage();
      }
      this.$emit("submit", false);
    },
    async handleSubmit() {
      let _this = this;
      let token = null;
      if (this.$recaptchaLoaded) {
        await this.$recaptchaLoaded();
        token = await this.$recaptcha("login");
      }
      if (_this.password.length > 0) {
        try {
          _this.loading = true;
          _this.response = {};
          await _this.doLogin({
            email: _this.email,
            password: _this.password,
            rememberMe: _this.rememberMe,
            token: token
          });
          if (_this.isAuthenticated) {
            global.EventBus.$emit("login");
            _this.$emit("loggedIn", true);
          }
          this.$emit("submit", true);
          if (_this.redirectParams) {
            _this.$router.push({
              name: _this.redirectParams.name,
              path: _this.redirectParams.path,
              params: _this.redirectParams.params
            });
          }
        } catch (response) {
          this.response = response;
          console.log("errors", response.errors[0].error);
          if (
            response.errors[0].error.startsWith(
              "it.digi.ebsn.abbondanza.exception.OlojinFidelityDisabledException"
            ) ||
            response.errors[0].error.startsWith(
              "it.digi.ebsn.abbondanza.exception.CamiFidelityDisabledException"
            )
          ) {
            this.$emit("submit", true);
            let message = "";
            let errorParts = response.errors[0].error.split(":");
            if (errorParts.length > 1) message = errorParts[1];
            this.response = {};
            this.$dialog.show(CardNotActive, {
              waitForResult: true,
              fullscreen: false,
              message: message.trim()
            });
          }
          if (
            response.errors[0].error.startsWith(
              "it.digi.ebsn.abbondanza.exception.OlojinUserNotActiveException"
            )
          ) {
            let message = "";
            let errorParts = response.errors[0].error.split(":");
            if (errorParts.length > 1) message = errorParts[1];
            this.response = {};
            let res = await this.$dialog.confirm({
              text: `<h3 class='primary--text text-center'>${message}</h3>`,
              class: "confirm-dialog",
              actions: {
                false: {
                  color: "var(--v-primary-base)",
                  rounded: true,
                  text: this.$t("common.no")
                },
                true: {
                  rounded: true,
                  text: this.$t("common.yes")
                }
              }
            });
            if (res) {
              AbbondanzaRegistrationService.resendRegistrationEmail();
            }
          }
        } finally {
          this.loading = false;
        }
      }
    },
    async openRegistrationDialog() {
      this.$emit("submit", false);
      let res = await this.$dialog.show(RegistrationUser, {
        waitForResult: true,
        fullscreen: this.$vuetify.breakpoint.smAndDown
      });
      if (res) {
        console.log("Registration popup", res);
      }
    }
  }
};
</script>
