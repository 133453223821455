var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "registration-user-container pb-0 pt-12 px-0",
      attrs: { fluid: "" }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-column align-center justify-space-between w-100 pa-3"
        },
        [
          _c("img", {
            style: { height: "70px" },
            attrs: { src: "/logo/logo.png", alt: "Logo" }
          }),
          _c("h2", {
            staticClass: "default--text",
            domProps: { innerHTML: _vm._s(_vm.$t("register.user.title")) }
          })
        ]
      ),
      _vm.step == 1
        ? _c(
            "div",
            {
              staticClass:
                "d-flex justify-center align-center flex-column w-100 grey lighten-1 pa-5 text-center"
            },
            [
              _c("h4", { staticClass: "default--text px-2" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("register.user.alreadyCustomer")) + "? "
                )
              ]),
              _c(
                "v-btn-toggle",
                {
                  attrs: { tile: "", color: "primary", group: "" },
                  on: { change: _vm.resetUserData },
                  model: {
                    value: _vm.hasCard,
                    callback: function($$v) {
                      _vm.hasCard = $$v
                    },
                    expression: "hasCard"
                  }
                },
                [
                  _c("v-btn", { attrs: { value: true, outlined: "" } }, [
                    _vm._v(" " + _vm._s(_vm.$t("common.yes")) + " ")
                  ]),
                  _c("v-btn", { attrs: { value: false, outlined: "" } }, [
                    _vm._v(" " + _vm._s(_vm.$t("common.no")) + " ")
                  ])
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "default--text mt-2 text-decoration-underline",
                  on: {
                    click: function($event) {
                      return _vm.openInBrowser(
                        "https://www.galasupermercati.it/privacy"
                      )
                    }
                  }
                },
                [_vm._v(" Privacy Policy ")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.hasCard != null
        ? _c(
            "div",
            [
              _vm.hasCard == true && _vm.step == 1
                ? _c("div", { staticClass: "pa-2" }, [
                    _c(
                      "div",
                      [
                        _c("UserData", {
                          ref: "userDataForm",
                          attrs: {
                            userData: _vm.userData,
                            hasCard: _vm.hasCard,
                            isFieldDisabled: _vm.hasCard == null,
                            isRegisterWithCard: true,
                            loading: _vm.chekingCard
                          },
                          on: {
                            "update:userData": function($event) {
                              _vm.userData = $event
                            },
                            "update:user-data": function($event) {
                              _vm.userData = $event
                            },
                            submit: _vm.checkUserCardData
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _c(
                    "div",
                    [
                      _c("NewCustomer", {
                        ref: "newCustomerDataForm",
                        attrs: {
                          userData: _vm.userData,
                          hasCard: _vm.hasCard,
                          isFieldDisabled: _vm.hasCard == null
                        },
                        on: {
                          "update:userData": function($event) {
                            _vm.userData = $event
                          },
                          "update:user-data": function($event) {
                            _vm.userData = $event
                          }
                        }
                      }),
                      _c("PrivacySection", {
                        ref: "newCustomerPrivacyForm",
                        attrs: { userData: _vm.privacyData },
                        on: {
                          "update:userData": function($event) {
                            _vm.privacyData = $event
                          },
                          "update:user-data": function($event) {
                            _vm.privacyData = $event
                          }
                        }
                      })
                    ],
                    1
                  ),
              (_vm.hasCard == true && _vm.step == 2) || _vm.hasCard == false
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-end pa-2" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-3",
                          attrs: {
                            depressed: "",
                            block: "",
                            rounded: "",
                            large: "",
                            color: "primary"
                          },
                          on: { click: _vm.register }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.hasCard
                                ? _vm.$t("register.user.signInCard")
                                : _vm.$t("register.user.signIn")
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("ResponseMessage", {
                staticClass: "mt-4 pa-2",
                attrs: { response: _vm.response }
              }),
              _vm.response.errorsMessage &&
              _vm.response.errorsMessage[0].code ==
                "error.registration.already.registered"
                ? _c(
                    "div",
                    { staticClass: "pa-2" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-3",
                          attrs: {
                            depressed: "",
                            block: "",
                            rounded: "",
                            large: "",
                            outlined: "",
                            color: "primary"
                          },
                          on: { click: _vm.login }
                        },
                        [_vm._v(_vm._s(_vm.$t("login.buttonLogin")))]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-3",
                          attrs: {
                            depressed: "",
                            block: "",
                            rounded: "",
                            large: "",
                            outlined: "",
                            color: "primary"
                          },
                          on: { click: _vm.openResetPasswordDialog }
                        },
                        [_vm._v(_vm._s(_vm.$t("login.recoverPassword")))]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _c("div", { staticClass: "d-flex justify-center pa-5" }, [
            _c("img", {
              staticClass: "card-img",
              attrs: { src: "/img_layout/carta-gala-2.png" }
            })
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }