import analyticsService from "~/service/analyticsService";
import EmbedVideo from "~/components/EmbedVideo.vue";
import { videoURLMatcher } from "~/helper";
export default {
  props: {
    proposal: { type: Object, required: true },
    position: { type: String, required: false }
  },
  computed: {
    link() {
      if (this.proposal.proposalUrl) {
        return this.proposal.proposalUrl;
      } else if (this.proposal.slug) {
        var categoryPrefix =
          global.config.categoryPrefix != undefined
            ? global.config.categoryPrefix
            : "/category/";
        return this.proposal.proposalUrl || categoryPrefix + this.proposal.slug;
      } else {
        return null;
      }
    }
  },
  methods: {
    handleLink() {
      //log event to analytics
      analyticsService.clickPromotion(this.proposal, this.position);
      if (this.proposal.slug) {
        this.$router.push({
          name: "Category",
          params: { pathMatch: this.proposal.slug }
        });
      } else if (this.proposal.proposalUrl) {
        let link = new URL(this.proposal.proposalUrl, window.location.href);
        //utilizzare stessa tecnica della page per capire se è un link interno o no....
        let path = link.pathname;
        if (link.search) {
          path = `${link.pathname}${link.search}`;
        }
        if (global.config.domainWhitelist.includes(link.hostname)) {
          let resolved = this.$router.resolve({
            path: path
          });
          if (
            resolved &&
            resolved.route.matched.length > 0 &&
            resolved.route.name != "DefaultRoute"
          ) {
            this.$router.push(resolved.route);
          } else {
            if (link.href.indexOf("http") > -1) {
              if (this.$platform_is_cordova) {
                //if app open on _system browser
                window.cordova.InAppBrowser.open(link.href, "_system");
              } else {
                // if site open in a new tab
                window.open(link.href);
              }
            }
          }
        } else if (videoURLMatcher.test(this.proposal.proposalUrl)) {
          //handle youtube links
          global.vm.$dialog.show(EmbedVideo, {
            waitForResult: true,
            fullscreen: global.vm.$vuetify.breakpoint.xsOnly,
            width: 700,
            videoURL: this.proposal.proposalUrl
          });
        } else {
          if (link.href.indexOf("http") > -1) {
            if (this.$platform_is_cordova) {
              window.cordova.InAppBrowser.open(link.href, "_system");
            } else {
              window.open(link.href);
            }
          }
        }
      }
    },
    handleView(entries, observer, isIntersecting) {
      if (isIntersecting && this.proposal) {
        analyticsService.viewPromotions(this.proposal, this.position);
      }
    },
    extractSlug(prefix, url) {
      let index = this.proposal.proposalUrl.lastIndexOf(prefix);
      if (index > -1) {
        let slug = url.slice(index + prefix.length, url.endsWith("/") ? -1 : 0);
        return slug;
      } else {
        return null;
      }
    }
  }
};
