import axios from "~/plugins/axios";

export default {
  subscribed() {
    return axios
      .get("/ebsn/api/recurrent-order/subscribed")
      .then(data => data.data)
      .catch(error => error);
  },
  boxList(recurrentOrderId, fromDate, fetchDetail) {
    let params = {
      recurrent_order_id: recurrentOrderId,
      from_date: fromDate,
      fetch_detail: fetchDetail
    };

    return axios
      .get("/ebsn/api/recurrent-order/list-order", {
        params: params
      })
      .then(data => data.data)
      .catch(error => error);
  },
  getTimeslots(addressId) {
    let params = {
      "address-id": addressId
    };

    return axios
      .get("/ebsn/api/timeslot/list-template", {
        params: params
      })
      .then(data => data.data)
      .catch(error => error);
  },
  subscribe(timeslotId, addressId, weekDay, consent) {
    let params = {
      timeslot_id: timeslotId,
      address_id: addressId,
      week_day: weekDay,
      consent: consent
    };

    return axios
      .post("/ebsn/api/recurrent-order/subscribe", undefined, { params })
      .then(response => {
        return response.data;
      })
      .catch(err => err);
  },
  googleBoxSurvey(params) {
    return axios
      .post("/ebsn/api/googlebox/survey", params)
      .then(response => {
        return response.data;
      })
      .catch(err => err);
  },
  stop(recurrentOrderId, cause) {
    let params = {
      recurrent_order_id: recurrentOrderId,
      cause: cause
    };

    return axios
      .post("/ebsn/api/recurrent-order/stop", undefined, { params })
      .then(response => {
        return response.data;
      })
      .catch(err => err);
  },
  checkPaymentMethod(
    recurrentOrderId,
    paymentTypeId,
    userPaymentAuthId,
    paymentData
  ) {
    let params = {
      recurrent_order_id: recurrentOrderId,
      payment_type_id: paymentTypeId,
      user_payment_auth_id: userPaymentAuthId,
      payment_data: paymentData
    };

    return axios
      .post("/ebsn/api/recurrent-order/check-payment-method", undefined, {
        params
      })
      .then(response => {
        return response.data;
      })
      .catch(err => err);
  },
  getAwardsOrders() {
    return axios.get("/ebsn/api/awards/view_orders").then(data => {
      return data.data;
    });
  },
  getAwardsStory() {
    return axios.get("/ebsn/api/awards/view_story").then(data => {
      return data.data;
    });
  }
};
