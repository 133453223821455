<template>
  <ProductListSlider
    :config="config"
    :products="products"
    :categoryBlockName="'ProductSlider'"
    :showMore="showMore"
    v-intersect.once="handleView"
    @selectProduct="handleClick"
    @reload="reload"
  />
</template>

<script>
import ProductListSlider from "./elements/ProductListSlider.vue";
import CouponsService from "@/service/couponsService";

// import categoryBlockType from "./categoryBlockType";
import deliveryReactive from "~/mixins/deliveryReactive";
import analyticsService from "~/service/analyticsService";
import get from "lodash/get";
import { mapGetters } from "vuex";

export default {
  name: "ProductSlider",
  props: {
    config: {
      type: Object,
      required: true
    },
    container: {
      type: Boolean,
      default: true
    }
  },
  components: { ProductListSlider },
  mixins: [deliveryReactive],
  data() {
    return {
      products: [],
      showMore: true
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    })
  },
  methods: {
    // handleView(entries, observer, isIntersecting) {
    //   if (isIntersecting) {
    //     analyticsService.viewPromotions(
    //       [this.proposal],
    //       this.position,
    //       this.products
    //     );
    //   }
    // },
    async reload() {
      this.products = [];
      if (this.isAuthenticated) {
        CouponsService.getCouponGallery(false).then(data => {
          this.products = data.giftCertificates;
        });
      }
      if (!this.products || this.products.length < 1) {
        this.$emit("hide");
      }
    },
    handleView(entries, observer, isIntersecting) {
      if (isIntersecting) {
        const position = get(this.config, "templateBlock.codInt");
        analyticsService.viewProducts(this.products, this.getName(), position);
      }
    },
    handleClick(payload) {
      analyticsService.clickProduct(
        payload.product,
        this.getName(),
        payload.index
      );
    },
    getName() {
      const categoryId = get(
        this.config,
        "metaData.categoryblocktype_ProductSlider.CATEGORY.categoryId"
      );
      const proposalTitle = get(
        this.config,
        "metaData.categoryblocktype_ProductSlider.TITLE"
      );
      return proposalTitle || "promo_" + categoryId;
    }
  },
  async created() {
    await this.reload();
  }
};
</script>
