<template>
  <v-form v-model="isValid" ref="form" @submit.prevent class="privacy-form">
    <v-row no-gutters>
      <v-col cols="12">
        <div class="pa-2 pb-8 grey lighten-1">
          <div class="text-body-0 text-uppercase font-weight-semibold my-2">
            {{ $t("profile.disclaimer2Title") }}
          </div>
          <label class="text-body-0">{{ $t("profile.disclaimer2") }}</label>
          <v-radio-group
            dense
            v-model="userData.marketing"
            :rules="[requiredRules()]"
            @change="handleMarketing"
          >
            <v-radio :value="'1'" on-icon="$checkboxOn" off-icon="$checkboxOff"
              ><template v-slot:label>
                <span class="disclaimer-label disclaimer-radio-label">{{
                  $t("disclaimer.radioLabelYes")
                }}</span>
              </template></v-radio
            >
            <v-radio :value="'0'" on-icon="$checkboxOn" off-icon="$checkboxOff">
              <template v-slot:label>
                <span class="disclaimer-label disclaimer-radio-label">{{
                  $t("disclaimer.radioLabelNo")
                }}</span>
              </template>
            </v-radio>
          </v-radio-group>
          <div v-if="userData.marketing === '1'">
            <div class="text-body-0 text-uppercase font-weight-semibold my-2">
              {{ $t("profile.contactChannels") }}
            </div>
            <div class="text-body-0 pr-8">
              {{ $t("profile.contactChannelsDesc") }}
            </div>
            <!-- <div>
              <div class="d-flex align-center justify-center my-2">
                <div class="flex-grow-1 text-body-0 pr-3">
                  {{ $t("profile.emailNewsletter") }}
                </div>
                <div class="ios-style-switch mr-2">
                  <v-switch
                    inset
                    color="primary"
                    hide-details
                    :ripple="false"
                  ></v-switch>
                </div>
              </div>
            </div> -->
            <div>
              <div class="d-flex align-center justify-center my-2">
                <div class="flex-grow-1 text-body-0 pr-3">
                  {{ $t("profile.emailPromo") }}
                </div>
                <div class="ios-style-switch mr-2">
                  <v-switch
                    inset
                    color="primary"
                    hide-details
                    :ripple="false"
                    v-model="userData.canaleMail"
                    false-value="0"
                    true-value="1"
                  ></v-switch>
                </div>
              </div>
            </div>
            <div>
              <div class="d-flex align-center justify-center my-2">
                <div class="flex-grow-1 text-body-0 pr-3">
                  {{ $t("profile.sms") }}
                </div>
                <div class="ios-style-switch mr-2">
                  <v-switch
                    inset
                    color="primary"
                    hide-details
                    :ripple="false"
                    v-model="userData.canaleSms"
                    false-value="0"
                    true-value="1"
                  ></v-switch>
                </div>
              </div>
            </div>
            <div>
              <div class="d-flex align-center justify-center my-2">
                <div class="flex-grow-1 text-body-0 pr-3">
                  {{ $t("profile.notifications") }}
                </div>
                <div class="ios-style-switch mr-2">
                  <v-switch
                    inset
                    color="primary"
                    hide-details
                    :ripple="false"
                    v-model="userData.canalePush"
                    false-value="0"
                    true-value="1"
                  ></v-switch>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3 pa-2 grey lighten-1">
          <div class="text-body-0 text-uppercase font-weight-semibold my-2">
            {{ $t("profile.disclaimer3Title") }}
          </div>
          <label class="text-body-0">{{ $t("profile.disclaimer3") }}</label>
          <v-radio-group
            dense
            v-model="userData.profilazione"
            :rules="[requiredRules()]"
          >
            <v-radio :value="'1'" on-icon="$checkboxOn" off-icon="$checkboxOff"
              ><template v-slot:label>
                <span class="disclaimer-label disclaimer-radio-label">{{
                  $t("disclaimer.radioLabelYes")
                }}</span>
              </template></v-radio
            >
            <v-radio :value="'0'" on-icon="$checkboxOn" off-icon="$checkboxOff">
              <template v-slot:label>
                <span class="disclaimer-label disclaimer-radio-label">{{
                  $t("disclaimer.radioLabelNo")
                }}</span>
              </template>
            </v-radio>
          </v-radio-group>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>
<style lang="scss">
.privacy-form {
  .disclaimer-label {
    color: var(--v-default-base);
    font-size: 16px;
    line-height: 18px;
  }
}
</style>
<script>
import { requiredValue } from "~/validator/validationRules";

export default {
  name: "PrivacySection",
  props: {
    userData: { type: Object, required: true }
  },
  data() {
    return {
      isValid: false,
      response: {},
      requiredRules: requiredValue
    };
  },
  methods: {
    handleMarketing() {
      this.userData.canaleMail = this.userData.marketing;
      this.userData.canaleSms = this.userData.marketing;
      this.userData.canalePush = this.userData.marketing;
    }
  }
};
</script>
