export default {
  data() {
    return {};
  },
  methods: {
    handleReceiptDownload(url, fileName) {
      // eslint-disable-next-line no-unused-vars
      var ref;
      if (this.$platform_is_cordova) {
        // eslint-disable-next-line no-undef
        if (device.platform === "iOS") {
          if (window.cordova && window.cordova.InAppBrowser) {
            ref = window.cordova.InAppBrowser.open(
              url,
              "_blank",
              "footer=no,location=no,toolbar=yes,zoom=no,hardwareback=no,enableViewportScale=yes"
            );
          }
        } else {
          window.requestFileSystem(
            // LocalFileSystem.PERSISTENT,
            window.TEMPORARY,
            0,
            function(fs) {
              fs.root.getFile(
                fileName + ".pdf",
                { create: true, exclusive: false },
                function(fileEntry) {
                  console.log(
                    "fileEntry is file? " + fileEntry.isFile.toString()
                  );
                  fileEntry.createWriter(
                    function(fileWriter) {
                      var oReq = new XMLHttpRequest();
                      oReq.open("GET", url, true);
                      oReq.responseType = "arraybuffer";
                      oReq.onload = function() {
                        var blob = new Blob([oReq.response], {
                          type: "application/pdf"
                        });
                        if (blob) {
                          fileWriter.write(blob);

                          fileWriter.onwriteend = function() {
                            var urlFile = fileEntry.toURL();
                            if (urlFile.indexOf("file://") === -1) {
                              urlFile = fileEntry.nativeURL;
                            }
                            window.cordova.plugins.fileOpener2.showOpenWithDialog(
                              urlFile,
                              "application/pdf",
                              {
                                error: function error(err) {
                                  console.error(err);

                                  global.EventBus.$emit("error", {
                                    error: err,
                                    message: global.EventBus.$t(
                                      "errors.errorNoappAvailable"
                                    )
                                  });
                                },
                                success: function success() {
                                  console.log("success with opening the file");
                                },
                                position: [0, 0]
                              }
                            );
                          };

                          fileWriter.onerror = function(err) {
                            global.EventBus.$emit("error", {
                              error: err,
                              message: global.EventBus.$t(
                                "errors.errorLoadingInvoice"
                              )
                            });
                            console.error(err);
                          };
                        } else console.error("we didnt get an XHR response!");
                      };
                      oReq.send(null);
                    },
                    function(err) {
                      console.error(err);
                    }
                  );
                },
                function(err) {
                  console.error("error getting file! " + err);
                }
              );
            },
            function(err) {
              console.error("error getting persistent fs! " + err);
            }
          );
        }
      } else {
        window.open(url, "_blank");
      }
    }
  }
};
