<template>
  <!-- this type of banner is an horizontal card -->
  <v-card
    rounded="md"
    :href="link"
    @click.prevent="handleLink"
    :style="getStyle('BACKGROUND_COLOR', 'TEXT_COLOR')"
    class="proposal-card-horizontal-responsive"
    outlined
    v-intersect.once="handleView"
  >
    <v-row no-gutters>
      <v-col cols="12" md="6" :style="getStyle('BG_ICON_COLOR')">
        <v-img
          class="image-card"
          :src="imgSrc"
          :alt="proposal.imgAlt"
          :title="proposal.imgDescription"
          :contain="
            $ebsn.meta(
              proposal,
              'metaData.category_proposal_type.IMAGE_CONTAIN',
              false
            )
          "
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="d-flex flex-column justify-center align-center align-md-start"
      >
        <v-card-title v-if="proposal.descr" class="secondary--text">
          {{ proposal.descr }}
        </v-card-title>
        <v-card-text
          v-if="proposal.content"
          class="secondary--text"
          v-html="proposal.content"
        >
        </v-card-text>
        <v-card-actions
          v-if="link && $ebsn.meta(proposal, 'category_proposal_type.SHOW_BTN')"
        >
          <v-btn
            :href="link"
            @click.prevent="handleLink"
            depressed
            large
            color="primary"
            :style="getStyle('TEXT_COLOR', 'BACKGROUND_COLOR')"
          >
            {{
              $ebsn.meta(
                proposal,
                "category_proposal_type.BTN_TEXT",
                "Visualizza"
              )
            }}
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>
<style lang="scss">
.proposal-card-horizontal-responsive {
  overflow: hidden;
  height: 100%;
  .image-card {
    height: 100%;
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      height: initial;
    }
  }
  .header {
    background-color: var(--v-secondary-base);
    color: var(--v-white-base);
    width: fit-content;
    border-radius: $border-radius-root;
    padding: 0px 10px;
    font-size: 14px;
    font-weight: 800;
  }
  .row {
    height: 100%;
  }
}
</style>
<script>
import banner from "~/mixins/banner";

import get from "lodash/get";

export default {
  name: "ProposalCardHorizontalResponsive",
  props: {
    proposal: { type: Object, required: true }
  },
  mixins: [banner],
  computed: {
    imgSrc() {
      let prop =
        "metaData.category_proposal_type." +
        (!this.$vuetify.breakpoint.xsOnly ? "IMAGE_DESKTOP" : "IMAGE_MOBILE");
      return get(this.proposal, prop, this.proposal.img);
    }
  },
  methods: {
    getStyle(backgroundPath, colorPath) {
      let style = {};

      if (backgroundPath) {
        let backgroundColor = get(
          this.proposal,
          `metaData.category_proposal_type.${backgroundPath}`
        );
        if (backgroundColor) {
          style.backgroundColor = backgroundColor + " !important";
        }
      }
      if (colorPath) {
        let textColor = get(
          this.proposal,
          `metaData.category_proposal_type.${colorPath}`
        );
        if (textColor) {
          style.color = textColor + " !important";
        }
      }

      return style;
    }
  }
};
</script>
