<template>
  <v-menu
    ref="menu"
    v-model="xmenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="field.value"
        :label="field.label"
        :required="field.required == '1'"
        readonly
        outlined
        v-bind="attrs"
        v-on="on"
      >
      </v-text-field>
    </template>
    <v-date-picker
      locale="it-IT"
      ref="picker"
      v-model="field.value"
      min="1910-01-01"
      first-day-of-week="1"
      type="date"
      no-title
      @input="xmenu = false"
    ></v-date-picker>
  </v-menu>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "NinjaFormsDatePicker",
  props: ["field", "menu"],
  data() {
    return {
      xmenu: this.menu
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      user: "cart/getUser"
    }),
    birthDate: {
      get: function() {
        if (this.field.value) {
          return this.$DateTime
            .fromISO(this.field.value)
            .toFormat("dd/MM/yyyy");
        } else {
          return null;
        }
      },
      set: function(value) {
        this.field.value = this.$DateTime.fromISO(value).toFormat("dd/MM/yyyy");
      }
    }
  }
};
</script>
