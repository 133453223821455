import dayjs from "dayjs";
function calculateHourRow(serviceHours, date, offsetDay = 1) {
  function checkCustomHours(customDate) {
    let result = null;
    if (serviceHours.custom && serviceHours.custom.length) {
      let customOpening = serviceHours.custom.filter(element =>
        customDate.isSame(dayjs(element.beginDate), "day")
      );
      if (customOpening && customOpening.length > 0) {
        let condensedHours = customOpening.reduce(
          (accumulator, element, index) => {
            let hours = element.note
              ? element.note
              : `dalle ${element.beginHour} alle ${element.endHour}`;
            return (
              accumulator +
              (index > 0 ? ", " : "") +
              "<span>" +
              hours +
              "</span>"
            );
          },
          ""
        );

        result = condensedHours;
      }
    }
    return result;
  }
  function checkDefaultHours(weekDay) {
    let result = null;
    if (serviceHours.default && serviceHours.default.length) {
      let defaultOpening = serviceHours.default.filter(
        element => element.beginWeekDay == weekDay + offsetDay
      );
      if (defaultOpening && defaultOpening.length > 0) {
        let condensedHours = defaultOpening.reduce((accumulator, element) => {
          let hours = element.note
            ? element.note
            : `dalle ${element.beginHour} alle ${element.endHour}`;
          return (
            accumulator + "<span class='partial-hours'>" + hours + "</span>"
          );
        }, "");

        result = condensedHours;
      }
    }
    return result;
  }

  try {
    let customOpening = checkCustomHours(date);

    if (customOpening) {
      return customOpening;
    } else {
      let weekDay = date.day();
      let defaultHours = checkDefaultHours(weekDay);
      if (defaultHours) return defaultHours;
    }

    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
}

function isOpenRightNow(serviceHours, offsetDay = 1) {
  try {
    let today = dayjs();
    let todayHoursArray = [];
    let open = false;

    if (serviceHours.custom && serviceHours.custom.length) {
      todayHoursArray = serviceHours.custom.filter(element =>
        dayjs(element.beginDate).isSame(today, "day")
      );
    }
    if (todayHoursArray.length < 1) {
      let weekDay = today.day() + offsetDay;
      todayHoursArray = serviceHours.default.filter(
        element => element.beginWeekDay == weekDay
      );
    }
    todayHoursArray.forEach(todayHours => {
      if (todayHours) {
        // check if is open
        let beginTime = dayjs(todayHours.beginHour, "HH:mm");
        let endTime = dayjs(todayHours.endHour, "HH:mm");

        if (today.isAfter(beginTime) && today.isBefore(endTime)) {
          console.log("open", todayHours.beginHour, todayHours.endHour);
          open = true;
        }
      }
    });

    return open;
  } catch (err) {
    console.log(err);
    return false;
  }
}

function isOpenRightNowMessage(serviceHours, offsetDay = 1) {
  function findDayHours(day) {
    let todayHoursArray = [];
    if (serviceHours.custom && serviceHours.custom.length) {
      todayHoursArray = serviceHours.custom.filter(element =>
        dayjs(element.beginDate).isSame(day, "day")
      );
    }
    if (todayHoursArray.length < 1) {
      let weekDay = day.day() + offsetDay;
      todayHoursArray = serviceHours.default.filter(
        element => element.beginWeekDay == weekDay
      );
    }
    return todayHoursArray;
  }
  try {
    let today = dayjs();
    let message = "";
    let et = null;

    let todayArray = findDayHours(today);
    todayArray.sort((a, b) => a.serviceHourId - b.serviceHourId);
    todayArray.forEach(todayHours => {
      if (todayHours) {
        // check if is open
        let beginTime = dayjs(todayHours.beginHour, "HH:mm");
        let endTime = dayjs(todayHours.endHour, "HH:mm");

        if (today.isAfter(beginTime) && today.isBefore(endTime)) {
          message = "chiude alle " + todayHours.endHour;
        } else if (et && today.isAfter(et) && today.isBefore(beginTime)) {
          message = "apre alle " + todayHours.beginHour;
        } else {
          et = endTime;
        }
      }
    });
    if (message === "") {
      let tomorrowArray = findDayHours(dayjs().add(1, "day"));
      if (tomorrowArray.length > 0) {
        tomorrowArray.sort((a, b) => a.serviceHourId - b.serviceHourId);
        message = "apre alle " + tomorrowArray[0].beginHour;
      }
    }

    return message;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export { calculateHourRow, isOpenRightNow, isOpenRightNowMessage };
