<template>
  <v-card class="product-card-big pa-4">
    <v-row>
      <v-col cols="12" sm="4" lg="3" xl="2">
        <v-img
          width="170"
          height="170"
          :src="product.mediaURL"
          onerror="this.onerror=null;this.src='/no-icon.png'"
          class="product-img align-self-center"
          :alt="`Immagine ${product.name}`"
          :title="
            product.name + ' (' + product.codInt + '-' + product.codVar + ')'
          "
        />
      </v-col>
      <v-col cols="12" sm="8" lg="9" xl="10">
        <div
          class="description d-flex flex-column justify-left w-100 h-100 mt-3 ml-1 ml-sm-0"
        >
          <div class="name font-weight-bold">
            {{ product.name }} {{ product.shortDescr }}
            {{ product.description }}
          </div>
          <ebsn-meta
            :target="product"
            path="productInfos.EXTENDED_DESCRIPTION"
            tag="div"
          />
          <v-spacer />
          <div
            v-if="selectedOptions.customWeight"
            class="cart-item-info"
            @click.stop.prevent="openOptionsModal(product.selectOptions)"
          >
            <em>Grammatura: {{ selectedOptions.customWeight }}gr</em>
          </div>
          <!-- v-if="product.priceDisplay && product.available > 0" -->
          <div v-if="product.available > 0" class="actions" @click.stop.prevent>
            <product-price v-if="product.priceDisplay" :product="product" />
            <v-spacer />
            <product-qty
              :selectedOptions="selectedOptions"
              :product="product"
              :item="item"
              :position="position"
            />
          </div>
          <div
            v-else
            class="actions justify-center text-center text-caption error--text font-weight-bold"
          >
            {{ $t("product.notAvailable") }}
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<style lang="scss">
.product-card-big {
  .actions {
    display: flex;
  }
}
</style>
<script>
import ProductPrice from "@/components/product/ProductPrice.vue";
import ProductQty from "@/components/product/ProductQty.vue";
import productMixin from "~/mixins/product";
import { mapGetters } from "vuex";

export default {
  components: { ProductPrice, ProductQty },
  name: "ProductSlider",
  props: {
    product: { type: Object, required: true },
    position: {
      type: Number,
      default: 0
    }
  },
  mixins: [productMixin],
  data() {
    return {
      selectedOptions: {}
    };
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem",
      isAuthenticated: "cart/isAuthenticated"
    }),
    item() {
      let item = this.getItem({
        product: this.product,
        ...this.selectedOptions
      });
      return item;
    }
  }
};
</script>
