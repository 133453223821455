<template>
  <div>
    <label>{{ data.label }}</label>
    <ul>
      <li v-for="option in data.options" :key="option.key">
        <v-checkbox
          v-model="selected"
          :label="option.label"
          :value="option.value"
          @change="update"
        ></v-checkbox>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "NinjaFormsListCheckbox",
  props: {
    data: { type: Object }
  },
  data() {
    return {
      selected: []
    };
  },
  created() {
    this.data.value = [];
  },
  methods: {
    update() {
      this.data.value = this.selected;
    }
  }
};
</script>
