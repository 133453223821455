<template>
  <div class="proposal-tutorial-slider swiper-container-wrapper">
    <swiper :options="swiperOption" ref="swiperRef" v-if="proposals">
      <swiper-slide v-for="proposal in proposals" :key="proposal.id">
        <ProposalTutorialSlide
          :proposal="proposal"
          @skipTutorial="skipTutorial"
          @openLogin="openLogin"
        />
      </swiper-slide>
      <div
        v-if="proposals.length > 0"
        class="swiper-pagination swiper-pagination-tutorial"
        slot="pagination"
      ></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
  </div>
</template>
<style lang="scss">
.proposal-tutorial-slider {
  &.swiper-container-wrapper {
    height: 100%;
  }
  .swiper-container,
  .swiper-page {
    height: 100%;
    justify-content: space-evenly;
  }
  .swiper-pagination-tutorial {
    transform: none;
    justify-content: start;
    padding-left: 30px;
    bottom: 356px;
  }
  .swiper-button-next {
    position: relative;
    bottom: 375px;
    top: unset;
    right: 30px;
    margin-left: auto;
  }
  .swiper-button-disabled {
    opacity: 0;
  }
}
</style>
<script>
import ProposalTutorialSlide from "@/components/tutorial/ProposalTutorialSlide.vue";

export default {
  name: "ProposalTutorialSlider",
  components: {
    ProposalTutorialSlide
  },
  props: {
    proposals: { type: Array, required: true }
  },
  data() {
    return {
      swiperOption: {
        effect: "fade",
        slidesPerView: 1,
        autoplay: false,
        freeMode: false,
        observer: true,
        observeParents: true,
        watchOverflow: true,
        pagination: {
          el: ".swiper-pagination-tutorial",
          clickable: false
        },
        navigation: {
          nextEl: ".swiper-button-next"
        }
      }
    };
  },
  methods: {
    skipTutorial() {
      this.$emit("skipTutorial");
    },
    openLogin() {
      this.$emit("openLogin");
    }
  }
};
</script>
