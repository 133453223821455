<template>
  <div
    :id="`LeafletSlider-wh-${config.categoryBlockId}`"
    class="LeafletSlider-wh-row"
  >
    <ebsn-style
      :target="config"
      path="metaData.categoryblocktype_LeafletSlider.CSS_STYLE"
      :styleWrapper="`#LeafletSlider-wh-${config.categoryBlockId}`"
    />
    <v-container fluid class="py-0">
      <ebsn-meta
        :target="config"
        path="categoryblocktype_LeafletSlider.TITLE"
        tag="h2"
        :class="'category-block-title'"
        v-if="this.leaflets.length > 0"
      />
      <ebsn-meta
        :target="config"
        path="categoryblocktype_LeafletSlider.DESCRIPTION"
        tag="div"
      />
      <div style="position:relative" class="py-2" v-if="leaflets">
        <swiper :options="swiperOption">
          <swiper-slide v-for="leaflet in leaflets" :key="leaflet.leafletId">
            <LeafletCard :leaflet="leaflet" />
          </swiper-slide>
        </swiper>
        <div
          v-if="
            $ebsn.meta(
              config,
              'categoryblocktype_LeafletSlider.SHOW_BULLETS',
              true
            ) && leaflets.length > 1
          "
          :id="`leaflet-pagination-${config.categoryBlockId}`"
          class="swiper-pagination"
        ></div>
        <div
          v-if="
            $ebsn.meta(
              config,
              'categoryblocktype_LeafletSlider.SHOW_ARROWS',
              false
            ) && leaflets.length > 1
          "
          :id="`leaflet-slider-prev-${config.categoryBlockId}`"
          class="swiper-button-prev"
        ></div>
        <div
          v-if="
            $ebsn.meta(
              config,
              'categoryblocktype_LeafletSlider.SHOW_ARROWS',
              false
            ) && leaflets.length > 1
          "
          :id="`leaflet-slider-next-${config.categoryBlockId}`"
          class="swiper-button-next"
        ></div>
      </div>
    </v-container>
  </div>
</template>
<script>
import LeafletCard from "@/components/leaflet/LeafletCard.vue";

import categoryBlockType from "./categoryBlockType";

import leafletService from "~/service/leafletService.js";
import { mapGetters } from "vuex";

export default {
  name: "LeafletSlider",
  components: { LeafletCard },
  props: {
    config: { type: Object, required: true }
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser",
      isAuthenticated: "cart/isAuthenticated",
      getSelectedStore: "custom/getSelectedStore"
    }),
    selectedStore() {
      if (this.isAuthenticated) {
        return this.user.pdv;
      } else {
        return this.getSelectedStore;
      }
    }
  },
  data() {
    return {
      leaflets: [],
      swiperDefaultOption: {
        slidesPerView: "2",
        spaceBetween: 10,
        autoplay: false,
        watchOverflow: true,
        loop: false,
        pagination: {
          el: `#leaflet-pagination-${this.config.categoryBlockId}`,
          clickable: true
        },
        navigation: {
          prevEl: `#leaflet-slider-prev-${this.config.categoryBlockId}`,
          nextEl: `#leaflet-slider-next-${this.config.categoryBlockId}`
        }
      }
    };
  },
  methods: {
    async reload() {
      if (this.selectedStore) {
        this.leaflets = await leafletService.search({
          parent_warehouse_id: this.selectedStore
        });
      } else {
        this.leaflets = [];
      }
      this.$emit("loaded", this.leaflets ? this.leaflets.length : 0);
    }
  },
  mixins: [categoryBlockType],
  async mounted() {
    await this.reload();
  }
};
</script>
