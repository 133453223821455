import axios from "~/plugins/axios";
export default {
  findUser(first_name, last_name, fidelity_card) {
    let url = `/ebsn/api/abbondanza-registration/getAnagrafica?fidelityCard=${fidelity_card}&firstName=${first_name}&lastName=${last_name}`;

    //Test
    //let url = `/ebsn/api/abbondanza-registration/getAnagraficaTest?fidelityCard=${fidelity_card}&firstName=${first_name}&lastName=${last_name}`;

    return axios.get(url).then(data => {
      return data.data;
    });
  },
  registerUser(user) {
    let url = "/ebsn/api/abbondanza-registration/register_user";
    return axios
      .post(url, user)
      .then(response => {
        return response.data;
      })
      .catch(err => err);
  },
  recoverPassword(email) {
    let url = `/ebsn/api/abbondanza-registration/recover_password?email=${email}`;

    return axios.post(url).then(data => {
      return data.data;
    });
  },
  changePassword(params) {
    let url = `/ebsn/api/profile/changePassword?oldPassword=${params.oldPassword}&newPassword1=${params.newPassword1}&newPassword2=${params.newPassword2}`;
    return axios
      .post(url)
      .then(response => {
        return response.data;
      })
      .catch(err => err);
  },
  verifyCard(card) {
    let url = `/ebsn/api/abbondanza-registration/verify_card?fidelityCard=${card}`;

    return axios.post(url).then(data => {
      return data.data;
    });
  },
  createVirtualCard() {
    let url = `/ebsn/api/fidelityCard/createVirtualCard`;

    return axios.get(url).then(data => {
      return data.data;
    });
  },
  getPoints() {
    let url = `/ebsn/api/fidelityCard/getPoints`;

    return axios.get(url).then(data => {
      return data.data.data;
    });
  },
  transferPoints(data) {
    let url = `/ebsn/api/fidelityCard/transferPoints?toFidelityCard=${data.toFidelityCard}&points=${data.points}`;
    // let params = { toFidelityCard: data.toFidelityCard, points: data.points };
    return axios.post(url).then(data => {
      return data.data;
    });
  },
  getUserDetail() {
    let url = "/ebsn/api/profile/getUserData";
    return axios
      .get(url)
      .then(response => response.data.data.value)
      .catch(err => err);
  },
  getPrivacyData() {
    let url = `/ebsn/api/profile/getPrivacyData`;

    return axios.get(url).then(data => {
      return data.data.data;
    });
  },
  updatePrivacy(data) {
    let url = `/ebsn/api/profile/updatePrivacyData`;
    return axios.post(url, { ...data }).then(data => {
      return data.data;
    });
  },
  updateUserData(data) {
    let url = `/ebsn/api/profile/updateUserData`;
    return axios.post(url, { ...data }).then(data => {
      return data.data;
    });
  },
  updatePdv(pdvId) {
    let url = `/ebsn/api/profile/updatePdv?pdv=${pdvId}`;
    return axios.post(url).then(data => {
      return data.data;
    });
  },
  anonymizeAccount() {
    let url = `/ebsn/api/profile/deleteUser`;
    return axios.post(url).then(data => {
      return data.data;
    });
  },
  updateToken(token) {
    let url = `/ebsn/api/profile/updateToken?token=${token}`;
    return axios.post(url).then(data => {
      return data.data;
    });
  },
  resendRegistrationEmail() {
    let url = `/ebsn/api/abbondanza-registration/resendRegistrationEmail`;
    return axios.post(url).then(data => {
      return data.data;
    });
  }
};
