var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "progress-bar-block mx-n2 px-2 py-4 mb-4" },
    [
      _c("TitleRow", {
        attrs: {
          config: _vm.config,
          categoryBlockName: "ProgressBarBlock",
          showMore: true
        }
      }),
      _c("ebsn-meta", {
        attrs: {
          target: _vm.config,
          path: "categoryblocktype_ProgressBarBlock.DESCRIPTION",
          tag: "div"
        }
      }),
      _vm.points != null
        ? _c("ExpenseProgressBar", {
            attrs: { points: _vm.points, segments: _vm.segments }
          })
        : _vm._e(),
      _c("div", { staticClass: "default--text font-weight-regular" }, [
        _vm._v(
          " " + _vm._s(_vm.$t("coupons.welcomeDiscounts.yourExpense")) + " "
        ),
        _c("strong", [_vm._v(_vm._s(_vm.points) + "€.")])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }