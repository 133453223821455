import ListService from "~/service/listService";
export default {
  data() {
    return {
      loading: true,
      lists: [],
      listName: "",
      createDisabled: false
    };
  },
  methods: {
    reload() {
      try {
        this.loading = true;
        ListService.getLists().then(data => {
          this.lists = data;
          this.loading = false;
        });
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    async createList() {
      try {
        this.createDisabled = true;
        let data = await ListService.createList(this.listName);
        global.EventBus.$emit("listCreated", data);
        this.lists = data;
        this.createDisabled = false;
        this.listName = "";
        return this.lists[this.lists.length - 1];
      } catch (error) {
        console.log(error);
      } finally {
        this.createDisabled = false;
      }
    },
    async setDefaultList(listId) {
      const lists = await ListService.setDefaultList(listId);
      this.lists = lists;
    },
    async removeList({ listId, listName }) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeList", {
          name: listName
        })
      });
      if (res) {
        const lists = await ListService.deleteList(listId);
        this.lists = lists;
        return lists;
      }
    },
    isEditGranted(list) {
      return list?.sharingData?.grant != "READ";
    },
    async renameList(list) {
      const lists = await ListService.renameList(list.id, list.tempName);
      this.lists = lists;
      return true;
    }
  }
};
