<template>
  <div class="progress-bar-block mx-n2 px-2 py-4 mb-4">
    <TitleRow
      :config="config"
      categoryBlockName="ProgressBarBlock"
      :showMore="true"
    />
    <ebsn-meta
      :target="config"
      path="categoryblocktype_ProgressBarBlock.DESCRIPTION"
      tag="div"
    />
    <ExpenseProgressBar
      v-if="points != null"
      :points="points"
      :segments="segments"
    />
    <div class="default--text font-weight-regular">
      {{ $t("coupons.welcomeDiscounts.yourExpense") }}
      <strong>{{ points }}€.</strong>
    </div>
  </div>
</template>
<style lang="scss">
.progress-bar-block {
  background-color: $white;
  --v-primary-base: #d50d52;
}
</style>
<script>
import ExpenseProgressBar from "../coupon/ExpenseProgressBar.vue";
import TitleRow from "./elements/TitleRow.vue";
import CopuonService from "@/service/couponsService";
export default {
  components: { ExpenseProgressBar, TitleRow },
  name: "ProgressBarBlock",
  props: {
    config: {
      type: Object,
      required: true
    },
    container: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      segments: [],
      points: null,
      loading: false,
      tab: "discounts"
    };
  },
  computed: {},
  methods: {
    async reload() {
      await this.getProgressBar();
    },
    async getProgressBar() {
      CopuonService.getCashbackBar().then(data => {
        this.segments = data.value.coupons;
        this.points = data.value?.userExpense;
      });
    }
  },
  async created() {
    await this.reload();
  }
};
</script>
