var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      ref: "couponCard",
      staticClass: "coupon-card rounded-lg d-flex pa-1",
      class: _vm.type,
      attrs: { elevation: "2", disabled: _vm.disabled },
      on: { click: _vm.showDetails }
    },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-center align-center flex-shrink-1" },
        [
          _vm.type == "voucher"
            ? _c("img", {
                staticClass: "coupon-img rounded-lg",
                attrs: { src: _vm.coupon.categoryProposal.img, alt: "" }
              })
            : _c("img", {
                staticClass: "coupon-img rounded-lg",
                attrs: {
                  src:
                    _vm.type == "activeDiscount" || _vm.type == "welcomeCoupon"
                      ? _vm.coupon.product.mediaURL
                      : _vm.coupon.mediaURL
                      ? _vm.coupon.mediaURL
                      : _vm.coupon.media[0].small,
                  onerror: "this.onerror=null;this.src='/no-icon.png'",
                  alt:
                    "Coupon-" +
                    (_vm.type == "activeDiscount"
                      ? _vm.coupon.barCode
                      : _vm.coupon.name)
                }
              })
        ]
      ),
      _vm.type == "activeDiscount"
        ? _c(
            "div",
            {
              staticClass:
                "d-flex flex-column justify-center text-center w-100 ml-2 px-1"
            },
            [
              _c("div", { staticClass: "text-body-0 font-weight-semibold" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("coupons.discounts.activeVoucher")) + " "
                )
              ]),
              _c(
                "div",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        rounded: "",
                        color: "primary",
                        block: "",
                        large: "",
                        outlined: ""
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.showDetails.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("coupons.discounts.view")))]
                  )
                ],
                1
              )
            ]
          )
        : _vm.type == "voucher" || _vm.type == "welcomeCoupon"
        ? _c(
            "div",
            {
              staticClass:
                "d-flex flex-column justify-center text-center w-100 ml-2 px-1"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "coupon-description flex-column d-flex justify-center align-center"
                },
                [
                  _vm.type == "voucher"
                    ? _c(
                        "div",
                        { staticClass: "font-weight-semibold text-body-2" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("coupons.discounts.forYou")) +
                              " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.type == "welcomeCoupon"
                    ? _c(
                        "div",
                        { staticClass: "font-weight-semibold text-body-2" },
                        [_vm._v(" " + _vm._s(_vm.$t(_vm.title)) + " ")]
                      )
                    : _vm._e(),
                  _vm.type == "voucher"
                    ? _c("div", { staticClass: "discount-name" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.amount) +
                            _vm._s(_vm.discountType) +
                            " "
                        )
                      ])
                    : _vm._e(),
                  _vm.type == "welcomeCoupon"
                    ? _c("div", { staticClass: "discount-name" }, [
                        _vm._v(" " + _vm._s(_vm.coupon.product?.name) + " ")
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "div",
                [
                  _vm.type == "welcomeCoupon"
                    ? _c("div", { staticClass: "voucher-name" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("coupons.discounts.onYourSpending")) +
                            " "
                        )
                      ])
                    : _c("div", { staticClass: "voucher-name" }, [
                        _vm._v(_vm._s(_vm.coupon.description))
                      ]),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        rounded: "",
                        color: "primary",
                        block: "",
                        large: ""
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.showDetails.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("coupons.discounts.view")))]
                  )
                ],
                1
              )
            ]
          )
        : _c(
            "div",
            {
              staticClass:
                "d-flex flex-column flex-grow-1 justify-space-around w-100 ml-2 px-1"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "coupon-description d-flex justify-space-between align-center"
                },
                [
                  _c("div", [
                    _vm.type == "discount"
                      ? _c(
                          "div",
                          { staticClass: "font-weight-semibold text-body-2" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("coupons.discounts.discountCoupon")
                                ) +
                                " "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.type == "donation"
                      ? _c(
                          "div",
                          { staticClass: "text-body-2 text-uppercase" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("coupons.discounts.donatePoints")
                                ) +
                                " "
                            )
                          ]
                        )
                      : _vm._e(),
                    _c("div", { class: `${_vm.type + "-name"}` }, [
                      _vm._v(" " + _vm._s(_vm.coupon.name) + " ")
                    ])
                  ]),
                  _vm.type == "discount" && !_vm.details
                    ? _c(
                        "div",
                        [
                          _c("v-icon", { attrs: { color: "grey" } }, [
                            _vm._v("$info")
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "d-flex flex-column justify-center align-center"
                },
                [
                  _c("div", { staticClass: "font-weight-bold py-1" }, [
                    _vm._v(
                      " " + _vm._s(_vm.coupon.warehousePromo?.view.bubble) + " "
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            rounded: "",
                            color: "primary",
                            block: "",
                            large: ""
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.activateModal.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.type == "discount" ? "Attiva" : "Dona ora"
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ]
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }