<template>
  <v-card outlined class="banner-tab">
    <ebsn-meta
      :target="config"
      path="categoryblocktype_BannerTab.TITLE"
      tag="h2"
    />
    <!-- e-->
    <ebsn-meta
      :target="config"
      path="categoryblocktype_BannerTab.DESCRIPTION"
      tag="div"
    />
    <div :class="{ 'custom-vertical-tab d-flex': isVertical }">
      <v-tabs
        v-model="selectedProposal"
        :color="$ebsn.meta(config, 'categoryblocktype_BannerTab.TABS_COLOR')"
        :vertical="isVertical"
        hide-slider
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab v-for="proposal in config.proposals" :key="proposal.id">
          <div class="d-flex flex-column">
            <div class="tab-title">{{ proposal.name }}</div>
            <div class="tab-description">{{ proposal.descr }}</div>
          </div>
        </v-tab>
      </v-tabs>
      <v-tabs-items
        v-model="selectedProposal"
        vertical
        class="d-none d-lg-flex"
      >
        <v-tab-item
          v-for="proposal in config.proposals"
          :key="proposal.id"
          eager
        >
          <component
            :is="getProposalModelName(proposal)"
            :proposal="proposal"
            class="h-100"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-card>
</template>
<style lang="scss">
.banner-tab {
  h2 {
    color: white;
    padding-left: 8px;
  }
  // @media #{map-get($display-breakpoints, 'md-only')} {
  //   height: 317px;
  // }
  // @media #{map-get($display-breakpoints, 'lg-only')} {
  //   height: 312px;
  // }
  // @media #{map-get($display-breakpoints, 'xl-only')} {
  //   height: 480px;
  // }
  .custom-vertical-tab {
    .v-tabs {
      width: 100%;
      @media #{map-get($display-breakpoints, 'md-only')} {
        width: 28%;
      }
      @media #{map-get($display-breakpoints, 'lg-only')} {
        width: 64%;
      }
      @media #{map-get($display-breakpoints, 'xl-only')} {
        width: 32%;
      }
    }
    // .v-slide-group__content {
    //   min-width: 250px;
    //   width: 25%;
    //   max-width: 400px !important;
    // }

    .v-tab {
      justify-content: flex-start;
      white-space: normal;
      text-align: start;
    }
    .v-item-group {
      flex-grow: 1 !important;
    }
    .v-tabs-bar .v-tab {
      height: 100px;
    }
    .tab-title {
      font-size: 18px;
      font-weight: bold;
      color: $secondary;
    }
  }
}
</style>
<script>
import deliveryReactive from "~/mixins/deliveryReactive";
import categoryBlockType from "./categoryBlockType";

import ProductService from "~/service/productService";

import Vue from "vue";
import ProductCardBig from "./elements/ProductCardBig.vue";
import ProposalImage from "./elements/ProposalImage.vue";

export default {
  components: { ProductCardBig, ProposalImage },
  name: "BannerTab",
  props: {
    config: {
      type: Object,
      required: true
    },
    container: {
      type: Boolean,
      default: true
    }
  },
  mixins: [deliveryReactive, categoryBlockType],
  data() {
    return {
      products: [],
      selectedProposal: null,
      isVertical: true,
      cssStyle: {
        color: "white"
      }
    };
  },
  methods: {
    async handleView() {
      return null;
    },
    async reload() {
      this.products = [];

      let categoryId = Vue.$ebsn.meta(
        this.config,
        "categoryblocktype_BannerTab.CATEGORY.categoryId"
      );
      if (categoryId) {
        let response = await ProductService.search({
          parent_category_id: categoryId,
          page_size: Vue.$ebsn.meta(
            this.config,
            "categoryblocktype_ProductTab.PRODUCT_LIMIT",
            12
          )
        });
        this.products = response.products;
        this.showMore = response.page.totItems > response.page.itemsPerPage;
        this.$emit("loaded", this.products ? this.products.length : 0);
      }
    }
  },
  async created() {
    await this.reload();
  }
};
</script>
