<template>
  <v-card
    rounded
    class="leaflet-card"
    :to="{
      name: 'LeafletDetails',
      params: { leafletId: leaflet.leafletId }
    }"
  >
    <div>
      <v-img
        :alt="cover.alt"
        eager
        contain
        :src="cover.url"
        class="rounded-lg"
      />
      <h4 class="mb-0">{{ leaflet.name }}</h4>
      <div>
        Dal {{ formattedFromDate }} al
        {{ $dayjs(leaflet.toDate).format("DD MMMM") }}
      </div>
    </div>
  </v-card>
</template>
<style lang="scss">
.leaflet-card {
  background-color: transparent !important;
  .leaflet-img {
    height: 100px;
  }
}
</style>
<script>
const COVER_TYPE_ID = 12;
export default {
  name: "LeafletCard",
  data() {
    return {};
  },
  props: {
    leaflet: { type: Object, required: true },
    small: { type: Boolean, default: false }
  },
  computed: {
    cover() {
      let media = this.leaflet.media?.find(
        m => m.formatTypeId == COVER_TYPE_ID
      );
      if (!media) {
        media = {
          alt: this.leaflet.title,
          description: this.leaflet.descr,
          url: `${this.leaflet.baseLocation}${this.leaflet.firstPagePreview}`
        };
      }
      return media;
    },
    firstPageSrc() {
      if (this.leaflet.media && this.leaflet.media.length > 0) {
        return `${this.leaflet.media[0].url}`;
      } else {
        return `${this.leaflet.baseLocation}${this.leaflet.firstPagePreview}`;
      }
    },
    formattedFromDate() {
      const fromDate = this.$dayjs(this.leaflet.fromDate);
      const toDate = this.$dayjs(this.leaflet.toDate);

      if (fromDate.month() === toDate.month()) {
        return fromDate.format("DD");
      } else {
        return fromDate.format("DD MMMM");
      }
    }
  }
};
</script>
