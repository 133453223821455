var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      staticClass: "privacy-form",
      on: {
        submit: function($event) {
          $event.preventDefault()
        }
      },
      model: {
        value: _vm.isValid,
        callback: function($$v) {
          _vm.isValid = $$v
        },
        expression: "isValid"
      }
    },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c(
              "div",
              { staticClass: "pa-2 pb-8 grey lighten-1" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "text-body-0 text-uppercase font-weight-semibold my-2"
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("profile.disclaimer2Title")) + " "
                    )
                  ]
                ),
                _c("label", { staticClass: "text-body-0" }, [
                  _vm._v(_vm._s(_vm.$t("profile.disclaimer2")))
                ]),
                _c(
                  "v-radio-group",
                  {
                    attrs: { dense: "", rules: [_vm.requiredRules()] },
                    on: { change: _vm.handleMarketing },
                    model: {
                      value: _vm.userData.marketing,
                      callback: function($$v) {
                        _vm.$set(_vm.userData, "marketing", $$v)
                      },
                      expression: "userData.marketing"
                    }
                  },
                  [
                    _c("v-radio", {
                      attrs: {
                        value: "1",
                        "on-icon": "$checkboxOn",
                        "off-icon": "$checkboxOff"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function() {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "disclaimer-label disclaimer-radio-label"
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("disclaimer.radioLabelYes"))
                                  )
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    }),
                    _c("v-radio", {
                      attrs: {
                        value: "0",
                        "on-icon": "$checkboxOn",
                        "off-icon": "$checkboxOff"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function() {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "disclaimer-label disclaimer-radio-label"
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("disclaimer.radioLabelNo"))
                                  )
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    })
                  ],
                  1
                ),
                _vm.userData.marketing === "1"
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-body-0 text-uppercase font-weight-semibold my-2"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("profile.contactChannels")) +
                              " "
                          )
                        ]
                      ),
                      _c("div", { staticClass: "text-body-0 pr-8" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("profile.contactChannelsDesc")) +
                            " "
                        )
                      ]),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-center justify-center my-2"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex-grow-1 text-body-0 pr-3" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("profile.emailPromo")) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "ios-style-switch mr-2" },
                              [
                                _c("v-switch", {
                                  attrs: {
                                    inset: "",
                                    color: "primary",
                                    "hide-details": "",
                                    ripple: false,
                                    "false-value": "0",
                                    "true-value": "1"
                                  },
                                  model: {
                                    value: _vm.userData.canaleMail,
                                    callback: function($$v) {
                                      _vm.$set(_vm.userData, "canaleMail", $$v)
                                    },
                                    expression: "userData.canaleMail"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ]),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-center justify-center my-2"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex-grow-1 text-body-0 pr-3" },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("profile.sms")) + " "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "ios-style-switch mr-2" },
                              [
                                _c("v-switch", {
                                  attrs: {
                                    inset: "",
                                    color: "primary",
                                    "hide-details": "",
                                    ripple: false,
                                    "false-value": "0",
                                    "true-value": "1"
                                  },
                                  model: {
                                    value: _vm.userData.canaleSms,
                                    callback: function($$v) {
                                      _vm.$set(_vm.userData, "canaleSms", $$v)
                                    },
                                    expression: "userData.canaleSms"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ]),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-center justify-center my-2"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex-grow-1 text-body-0 pr-3" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("profile.notifications")) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "ios-style-switch mr-2" },
                              [
                                _c("v-switch", {
                                  attrs: {
                                    inset: "",
                                    color: "primary",
                                    "hide-details": "",
                                    ripple: false,
                                    "false-value": "0",
                                    "true-value": "1"
                                  },
                                  model: {
                                    value: _vm.userData.canalePush,
                                    callback: function($$v) {
                                      _vm.$set(_vm.userData, "canalePush", $$v)
                                    },
                                    expression: "userData.canalePush"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ])
                    ])
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mt-3 pa-2 grey lighten-1" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "text-body-0 text-uppercase font-weight-semibold my-2"
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("profile.disclaimer3Title")) + " "
                    )
                  ]
                ),
                _c("label", { staticClass: "text-body-0" }, [
                  _vm._v(_vm._s(_vm.$t("profile.disclaimer3")))
                ]),
                _c(
                  "v-radio-group",
                  {
                    attrs: { dense: "", rules: [_vm.requiredRules()] },
                    model: {
                      value: _vm.userData.profilazione,
                      callback: function($$v) {
                        _vm.$set(_vm.userData, "profilazione", $$v)
                      },
                      expression: "userData.profilazione"
                    }
                  },
                  [
                    _c("v-radio", {
                      attrs: {
                        value: "1",
                        "on-icon": "$checkboxOn",
                        "off-icon": "$checkboxOff"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function() {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "disclaimer-label disclaimer-radio-label"
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("disclaimer.radioLabelYes"))
                                  )
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    }),
                    _c("v-radio", {
                      attrs: {
                        value: "0",
                        "on-icon": "$checkboxOn",
                        "off-icon": "$checkboxOff"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function() {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "disclaimer-label disclaimer-radio-label"
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("disclaimer.radioLabelNo"))
                                  )
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }