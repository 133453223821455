import ResetPasswordRequest from "@/views/profile/ResetPasswordRequest.vue";
export default {
  computed: {},
  methods: {
    async openResetPasswordDialog() {
      this.$emit("submit", false);
      let res = await this.$dialog.show(ResetPasswordRequest, {
        waitForResult: true,
        fullscreen: this.$vuetify.breakpoint.smAndDown
      });
      if (res) {
        console.log("Reset password popup", res);
      }
    }
  }
};
