<template functional>
  <div class="product-qty">
    <div
      class="qty-wrap rounded-sm"
      :class="{
        'not-empty': parent.quantity > 0,
        'show-add-btn': parent.quantity < 1
      }"
      @mousedown.stop
    >
      <v-btn
        aria-label="Diminuisci quantità"
        depressed
        color="grey lighten-1"
        small
        class="minus"
        @click.stop.prevent="
          parent.minus(props.updateCart, {
            infos: { ...props.selectedOptions }
          })
        "
      >
        <v-icon>$minus</v-icon>
      </v-btn>
      <div
        class="val-cnt"
        @click.stop.prevent=""
        @dblclick="parent.toggleShowInput"
        v-long-press="300"
        @long-press-start="parent.toggleShowInput"
        @mousedown.stop
      >
        <span class="val">{{ parent.quantity }}{{ parent.unit }}</span>

        <span class="small" v-if="parent.quantityPerUnit">{{
          parent.quantityPerUnit
        }}</span>
        <span class="small" v-else-if="parent.packageCount">{{
          parent.packageCount
        }}</span>
      </div>
      <v-btn
        aria-label="Aumenta quantità"
        class="plus grey"
        color="grey lighten-1"
        small
        depressed
        @click.stop.prevent="
          parent.plus(props.updateCart, {
            infos: {
              ...props.selectedOptions
            }
          })
        "
      >
        <v-icon>$plus</v-icon>
      </v-btn>
    </div>

    <v-btn
      depressed
      class="add-to-cart-btn"
      height="50"
      min-width="50"
      color="primary"
      v-if="props.showCartButton"
      @click.stop.prevent="
        parent.addToCart(
          parent.quantity,
          { infos: { ...selectedOptions } },
          {
            searchUid: props.product.searchUid,
            position: props.position
          }
        )
      "
    >
      <v-icon>$cart</v-icon>
      <span v-if="props.label" class="ml-2">{{ props.label }}</span>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "ProductQty",
  props: {
    product: { type: Object, required: true },
    item: { type: Object, required: false },
    updateCart: { type: Boolean, default: global.config.updateCart },
    label: { type: String },
    selectedOptions: { type: Object },
    position: { type: Number, default: undefined },
    showCartButton: { type: Boolean, default: true }
  }
};
</script>
