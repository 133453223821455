<template>
  <div
    class="news-slider-container"
    v-if="
      !loading &&
        this.config &&
        this.config.proposals &&
        this.config.proposals.length > 0
    "
  >
    <TitleRow
      :config="config"
      :categoryBlockName="'NewsSlider'"
      :showMore="true"
    />

    <div class="slider news-slider">
      <swiper :options="swiperOption" ref="swiperRef">
        <swiper-slide v-for="proposal in config.proposals" :key="proposal.id">
          <ProposalCardOverlay
            :proposal="proposal"
            :position="config.templateBlock.codInt"
          />
          <!-- <component
            :is="getProposalModelName(proposal)"
            :proposal="proposal"
            :position="config.templateBlock.codInt"
          /> -->
        </swiper-slide>
      </swiper>
      <div
        v-if="
          $ebsn.meta(
            config,
            'categoryblocktype_NewsSlider.SHOW_BULLETS',
            true
          ) && config.proposals.length > 1
        "
        :id="`news-slider-pagination-${config.categoryBlockId}`"
        class="swiper-pagination"
      ></div>
      <div
        v-if="
          $ebsn.meta(
            config,
            'categoryblocktype_NewsSlider.SHOW_ARROWS',
            false
          ) && config.proposals.length > 1
        "
        :id="`news-slider-prev-${config.categoryBlockId}`"
        class="swiper-button-prev"
      ></div>
      <div
        v-if="
          $ebsn.meta(
            config,
            'categoryblocktype_NewsSlider.SHOW_ARROWS',
            false
          ) && config.proposals.length > 1
        "
        :id="`news-slider-next-${config.categoryBlockId}`"
        class="swiper-button-next"
      ></div>
    </div>
  </div>
</template>
<style lang="scss">
.news-slider {
  .swiper-slide {
    height: auto;
    .proposal-card-overlay.v-card {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
<script>
import ProposalCardOverlay from "./elements/ProposalCardOverlay.vue";
import TitleRow from "@/components/categoryBlock/elements/TitleRow.vue";

import deliveryReactive from "~/mixins/deliveryReactive";
import categoryBlockType from "./categoryBlockType";

import CMService from "~/service/cmService";

export default {
  name: "NewsSlider",
  props: {
    config: { type: Object, required: true },
    container: { type: Boolean, default: true }
  },
  components: {
    ProposalCardOverlay,
    TitleRow
  },
  mixins: [deliveryReactive, categoryBlockType],
  data() {
    return {
      loading: true,
      swiperRef: null,
      swiperDefaultOption: {
        slidesPerView: 1,
        spaceBetween: 20,
        autoplay: true,
        watchOverflow: true,
        loop: false,
        pagination: {
          el: `#news-slider-pagination-${this.config.categoryBlockId}`,
          clickable: true
        },
        navigation: {
          prevEl: `#news-slider-prev-${this.config.categoryBlockId}`,
          nextEl: `#news-slider-next-${this.config.categoryBlockId}`
        }
      }
    };
  },
  methods: {
    reload() {
      if (this.$refs.swiperRef.swiperInstance) {
        this.$refs.swiperRef.swiperInstance.slideTo(0);
      }
      if (!this.config.proposals || this.config.proposals.length < 1) {
        this.$emit("hide");
      }
    },
    async fetchNews() {
      this.loading = true;
      // this filters are not actually working...
      let params = [
        {
          filterName: "filter[post_type]",
          filterValue: "post"
        },
        {
          filterName: "post_type",
          filterValue: "post"
        },
        {
          filterName: "type",
          filterValue: "post"
        },
        {
          filterName: "page",
          filterValue: 1
        }
      ];

      let res = await CMService.getCustomPostByFilters(
        "posts",
        params,
        this.$ebsn.meta(
          this.config,
          "categoryblocktype_RecipeSlider.NEWS_LIMIT",
          8
        )
      );

      if (res && res.data && res.data.length) {
        this.config.proposals = [];
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].type == "post") {
            this.config.proposals.push({
              id: res.data[i].id,
              categoryProposalType: { name: "ProposalCardOverlay" },
              slug: "",
              priority: 1,
              name: "",
              descr: res.data[i].title.rendered,
              content: "",
              img: res.data[i].featured_image_url,
              imgAlt: "sconto fedelta",
              imgDescription: "sconto fedelta",
              categoryProposedId: -1,
              clickable: false,
              link: "",
              proposalUrl: "/news/" + res.data[i].slug,
              proposalUrlType: "",
              metaData: {
                category_proposal_type: {
                  TEXT_COLOR: this.$ebsn.meta(
                    this.config,
                    "categoryblocktype_NewsSlider.TEXT_COLOR",
                    "bottom bottom-left"
                  ),
                  TEXT_POSITION: this.$ebsn.meta(
                    this.config,
                    "categoryblocktype_NewsSlider.TEXT_POSITION",
                    "bottom bottom-left"
                  )
                }
              }
            });
          }
        }
      }

      this.loading = false;
    }
  },
  mounted() {
    this.fetchNews();
  },
  created() {
    this.$emit("hide");
  }
};
</script>
