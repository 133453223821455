<template>
  <v-container fluid class="registration-user-container pb-0 pt-12 px-0">
    <div
      class="d-flex flex-column align-center justify-space-between w-100 pa-3"
    >
      <img src="/logo/logo.png" alt="Logo" :style="{ height: '70px' }" />
      <h2 class="default--text" v-html="$t('register.user.title')"></h2>
    </div>
    <div
      class="d-flex justify-center align-center flex-column w-100 grey lighten-1 pa-5 text-center"
      v-if="step == 1"
    >
      <h4 class="default--text px-2">
        {{ $t("register.user.alreadyCustomer") }}?
      </h4>
      <v-btn-toggle
        v-model="hasCard"
        tile
        color="primary"
        group
        @change="resetUserData"
      >
        <v-btn :value="true" outlined>
          {{ $t("common.yes") }}
        </v-btn>

        <v-btn :value="false" outlined>
          {{ $t("common.no") }}
        </v-btn>
      </v-btn-toggle>
      <div
        class="default--text mt-2 text-decoration-underline"
        @click="openInBrowser('https://www.galasupermercati.it/privacy')"
      >
        Privacy Policy
      </div>
    </div>
    <div v-if="hasCard != null">
      <div v-if="hasCard == true && step == 1" class="pa-2">
        <div>
          <!-- <UserEmailAddress
            ref="userEmailAddressDataForm"
            :userData.sync="userData"
            :hasCard="hasCard"
            :isFieldDisabled="hasCard == null"
          /> -->
          <UserData
            ref="userDataForm"
            :userData.sync="userData"
            :hasCard="hasCard"
            :isFieldDisabled="hasCard == null"
            :isRegisterWithCard="true"
            :loading="chekingCard"
            @submit="checkUserCardData"
          />
        </div>
      </div>
      <div v-else>
        <NewCustomer
          ref="newCustomerDataForm"
          :userData.sync="userData"
          :hasCard="hasCard"
          :isFieldDisabled="hasCard == null"
        />
        <PrivacySection
          :userData.sync="privacyData"
          ref="newCustomerPrivacyForm"
        />
      </div>
      <div
        class="d-flex justify-end pa-2"
        v-if="(hasCard == true && step == 2) || hasCard == false"
      >
        <v-btn
          @click="register"
          depressed
          block
          rounded
          large
          color="primary"
          class="mt-3"
          >{{
            hasCard
              ? $t("register.user.signInCard")
              : $t("register.user.signIn")
          }}</v-btn
        >
      </div>
      <ResponseMessage :response="response" class="mt-4 pa-2" />
      <div
        class="pa-2"
        v-if="
          response.errorsMessage &&
            response.errorsMessage[0].code ==
              'error.registration.already.registered'
        "
      >
        <v-btn
          @click="login"
          depressed
          block
          rounded
          large
          outlined
          color="primary"
          class="mt-3"
          >{{ $t("login.buttonLogin") }}</v-btn
        >
        <v-btn
          @click="openResetPasswordDialog"
          depressed
          block
          rounded
          large
          outlined
          color="primary"
          class="mt-3"
          >{{ $t("login.recoverPassword") }}</v-btn
        >
      </div>
    </div>
    <div class="d-flex justify-center pa-5" v-else>
      <img src="/img_layout/carta-gala-2.png" class="card-img" />
    </div>
  </v-container>
</template>

<style lang="scss">
.registration-user-container {
  .card-img {
    max-width: 100%;
  }
  .v-image__image--cover {
    background-size: inherit;
  }
  h1 {
    font-size: 50px !important;
  }
  .description {
    font-size: 14px;
  }
  a {
    color: var(--v-default-base) !important;
  }
  .v-card {
    border: 1px solid var(--v-grey-lighten3);
    color: var(--v-primary-base);
    height: 100%;
    .v-card__title {
      font-size: 30px;
      word-break: break-word;
      line-height: 42px;
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    h1 {
      font-size: 25px !important;
      line-height: 3rem;
    }
    .description {
      font-size: 12px !important;
    }
    .v-card {
      height: auto;
    }
  }
  .v-select .v-select__selection--comma {
    color: inherit !important;
    font-weight: normal !important;
  }
  .disclaimer-checkbox {
    .v-label {
      font-size: 12px !important;
    }
  }
  .v-btn-toggle > .v-btn.v-btn:not(:first-child) {
    border-left-width: 1px;
    margin-left: 20px;
  }
  .v-btn-toggle .v-btn.v-btn {
    border-radius: 28px !important;
    min-width: 80px;
    height: 45px !important;
    font-weight: 600;
    color: var(--v-default-base);
  }
  .v-btn-toggle--group > .v-btn.v-btn--active {
    background-color: $primary !important;

    .v-btn__content {
      color: $white;
    }
  }

  .v-btn-toggle--group > .v-btn.v-btn--active::before {
    opacity: 0;
  }
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0px;
  }
}
</style>

<script>
//import UserEmailAddress from "@/components/profile/form/UserEmailAddress";
import UserData from "@/components/profile/form/UserData";
import NewCustomer from "@/components/profile/form/NewCustomer.vue";
import PrivacySection from "@/components/profile/form/PrivacySection.vue";
import ResponseMessage from "@/components/ResponseMessage.vue";
import AbbondanzaRegistrationService from "@/service/abbondanzaRegistrationService";

import categoryMixins from "~/mixins/category";
import resetPassword from "@/mixins/resetPassword";
import oneSignal from "@/mixins/oneSignal";
import Login from "@/components/Login.vue";

export default {
  name: "RegistrationUser",
  data() {
    return {
      response: {},
      hasCard: null,
      step: 1,
      userData: {},
      privacyData: {},
      chekingCard: false
    };
  },
  mixins: [categoryMixins, resetPassword, oneSignal],
  components: {
    NewCustomer,
    ResponseMessage,
    UserData,
    PrivacySection
  },
  methods: {
    async register() {
      this.response = {};
      let newCustomerDataForm = this.$refs.newCustomerDataForm.$refs.form.validate();
      let newCustomerPrivacyForm = this.$refs.newCustomerPrivacyForm.$refs.form.validate();
      if (newCustomerDataForm && newCustomerPrivacyForm) {
        this.userData.acceptRegulation = "1";
        this.userData.token = await this.getOneSignalUUID();
        this.userData.channelMail = this.privacyData.canaleMail;
        this.userData.channelSms = this.privacyData.canaleSms;
        this.userData.channelPush = this.privacyData.canalePush;
        this.userData.disclaimerMarketing = this.privacyData.marketing;
        this.userData.disclaimerProfiling = this.privacyData.profilazione;
        AbbondanzaRegistrationService.registerUser(this.userData)
          .then(data => {
            if (data.response.status == 0) {
              this.$emit("submit", true);
              this.$router.push({
                name: "Home"
              });
            } else {
              this.response = data.response;
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    checkUserCardData(data) {
      this.chekingCard = true;
      AbbondanzaRegistrationService.findUser(
        data.firstName,
        data.lastName,
        data.fidelity
      )
        .then(data => {
          if (data.response.status == 0) {
            this.response = {};
            this.userData = data.data;
            this.privacyData.canaleMail = this.userData.channelMail;
            this.privacyData.canaleSms = this.userData.channelSms;
            this.privacyData.canalePush = this.userData.channelPush;
            this.privacyData.marketing = this.userData.disclaimerMarketing;
            this.privacyData.profilazione = this.userData.disclaimerProfiling;
            this.step = 2;
          } else {
            this.response = data.response;
          }
        })
        .finally(() => {
          this.chekingCard = false;
        });
    },
    resetUserData() {
      this.userData = {};
    },
    async login() {
      this.$emit("submit", false);
      let res = await this.$dialog.show(Login, {
        waitForResult: true,
        fullscreen: this.$vuetify.breakpoint.smAndDown
      });
      if (res) {
        console.log("Reset password popup", res);
      }
    },
    openInBrowser(url) {
      if (this.$platform_is_cordova && url.indexOf("http") > -1) {
        window.cordova.InAppBrowser.open(url, "_system");
      } else {
        window.open(url, "_blank");
      }
    }
  }
};
</script>
