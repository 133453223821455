import axios from "~/plugins/axios";

export default {
  getTimeslotList(showClosed, checkLocker) {
    let params = {
      "show-closed": showClosed,
      "check-locker": checkLocker
    };

    return axios
      .get("/ebsn/api/timeslot/list", { params: params })
      .then(data => {
        if (data.data.user.userId != -1) {
          let days = data.data.data.days;
          for (let i = 0; i < days.length; i++) {
            let day = days[i];
            for (let j = 0; j < day.timeslots.length; j++) {
              let timeslot = day.timeslots[j];
              if (timeslot.selected) {
                day.selected = true;
              }
            }
          }
          return days;
        } else {
          global.EventBus.$emit("isNotLoggedIn");
        }
      });
  },
  setTimeslot(date, timeslotId) {
    return axios
      .get("/ebsn/api/timeslot/select", {
        params: {
          sel_date: date,
          timeslot_id: timeslotId
        }
      })
      .then(data => {
        if (data.data.response.status == 0) {
          return data.data.data;
        } else {
          return false;
        }
      });
  },
  getAddressListByType(addressType) {
    addressType = addressType || "drive";
    return axios
      .get("/ebsn/api/user-address/delivery-service-list", {
        params: { address_type: addressType }
      })
      .then(data => {
        return data.data.data.addresses;
      });
  },
  setAddress(addressType, addressId, deliveryServiceId) {
    return axios
      .post("/ebsn/api/user-address/select", null, {
        params: {
          address_type: addressType,
          address_id: addressId,
          delivery_service_id: deliveryServiceId
        }
      })
      .then(data => {
        return data.data.data;
      });
  },
  setStore(storeId) {
    return axios
      .post("/ebsn/api/store/select", null, {
        params: {
          store_id: storeId
        }
      })
      .then(data => {
        return data.data.data;
      });
  },
  getAddresById(addressId) {
    // addressType = addressType || "drive";
    return axios
      .get("/ebsn/api/user-address/view", {
        params: { address_id: addressId }
      })
      .then(data => {
        return data.data.data;
      });
  },
  getProximityStore(
    latitude,
    longitude,
    distance = global.config.proximityDefaultDistance
  ) {
    // addressType = addressType || "drive";
    return axios
      .get("/ebsn/api/warehouse/find-by-coordinates", {
        params: {
          latitude: latitude,
          longitude: longitude,
          distance: distance
        }
      })
      .then(data => {
        return data.data.data;
      });
  },
  getAddressByDeliveryService(
    deliveryServiceId,
    latitude,
    longitude,
    distance = global.config.proximityDefaultDistance
  ) {
    return axios
      .get("/ebsn/api/user-address/delivery-service-list", {
        params: {
          delivery_service_id: deliveryServiceId,
          latitude: latitude,
          longitude: longitude,
          distance: distance
        }
      })
      .then(data => {
        return data.data.data;
      });
  },
  isValidAddress(address) {
    return axios.post("/ebsn/api/user-address/check", address).then(data => {
      if (data.data.response.status == 0) {
        return data.data.data;
      } else return false;
    });
  },
  updateAddress(address) {
    return axios.post("/ebsn/api/user-address/update", address).then(data => {
      if (data.data.response.status == 0) {
        global.EventBus.$emit("success", {
          message: global.EventBus.$t("addresses.setAddress")
        });
        return data.data.data;
      } else {
        return false;
      }
    });
  },
  updateAddressToNotify(form) {
    return axios
      .post("/ebsn/api/eurospin-address-form/save-new-address", form)
      .then(data => {
        if (data.data.response.status == 0) {
          global.EventBus.$emit("success", {
            message: global.EventBus.$t(
              "profile.addresses.deliveryNotifySuccess"
            )
          });
          return data.data.data;
        } else {
          global.EventBus.$emit("error", {
            message: global.EventBus.$t("profile.addresses.deliveryNotifyError")
          });
          return false;
        }
      });
  },
  removeAddress(addressId) {
    return axios
      .get("/ebsn/api/user-address/delete", {
        params: {
          address_id: addressId
        }
      })
      .then(data => {
        return data.data.data;
      });
  },
  getWarehousesList(addressType) {
    if (!addressType) {
      addressType = "drive";
    }
    return axios
      .get("/ebsn/api/user-address/list", {
        params: {
          address_type: addressType
        }
      })
      .then(data => {
        return data.data.data;
      });
  },
  subscribeCompany(companyCode) {
    return axios
      .get("/ebsn/api/shopatwork/subscribe", {
        params: {
          subscriptionCode: companyCode
        }
      })
      .then(data => {
        return data.data.data;
      });
  },
  subscribeShopAtWork(companyCode) {
    return axios
      .get("/ebsn/api/shopatwork/subscribe", {
        params: {
          subscriptionCode: companyCode
        }
      })
      .then(data => {
        return data.data;
      });
  },
  getDeliveryFee() {
    return axios.get("/ebsn/api/delivery-fee/view").then(data => {
      return data.data.data;
    });
  }
};
