const videoURLMatcher = /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(embed|shorts|watch)(?:.*v=|v\/|\/)([\w\-_]+)&?/;
function decodeVideoEmbedURL(videoUrl) {
  if (videoUrl) {
    let decodedURL = videoURLMatcher.exec(videoUrl);
    const youtubeEmbedTemplate =
      "https://www.youtube.com/embed/" +
      decodedURL[2] +
      "?&autoplay=1&controls=0";
    return youtubeEmbedTemplate;
    // const url = videoUrl.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    // const YId =
    //   undefined !== url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0];
    // if (YId === url[0]) {
    //   return videoUrl;
    // } else {
    //   const topOfQueue = youtubeEmbedTemplate.concat(YId);
    //   return topOfQueue;
    // }
  } else {
    return null;
  }
}
export { videoURLMatcher, decodeVideoEmbedURL };
