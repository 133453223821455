var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customer-data" },
    [
      _c(
        "div",
        { staticClass: "text-uppercase font-weight-semibold px-2 mt-6" },
        [_vm._v(" " + _vm._s(_vm.$t("profile.header.personalData")) + " ")]
      ),
      _c("div", { staticClass: "mb-2 text-body-3 px-2" }, [
        _vm._v(" * " + _vm._s(_vm.$t("profile.requiredFields")) + " ")
      ]),
      _c(
        "v-form",
        {
          ref: "form",
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          },
          model: {
            value: _vm.isValid,
            callback: function($$v) {
              _vm.isValid = $$v
            },
            expression: "isValid"
          }
        },
        [
          _c(
            "v-row",
            { staticClass: "px-2", attrs: { "no-gutters": "" } },
            [
              _vm.userData.fidelity
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mt-2",
                        attrs: {
                          label: `${_vm.$t("profile.cartaFedeltà")}`,
                          outlined: "",
                          rounded: "",
                          dense: "",
                          disabled: ""
                        },
                        model: {
                          value: _vm.userData.fidelity,
                          callback: function($$v) {
                            _vm.$set(_vm.userData, "fidelity", $$v)
                          },
                          expression: "userData.fidelity"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "5" } },
                [
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      label: `${_vm.$t(
                        "register.card.step-0.placeholder.surname"
                      )} *`,
                      rules: [_vm.requiredRules()],
                      required: "",
                      outlined: "",
                      rounded: "",
                      dense: "",
                      type: "text",
                      disabled: !!_vm.userData.fidelity
                    },
                    model: {
                      value: _vm.userData.lastName,
                      callback: function($$v) {
                        _vm.$set(_vm.userData, "lastName", $$v)
                      },
                      expression: "userData.lastName"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      label: `${_vm.$t(
                        "register.card.step-0.placeholder.name"
                      )} *`,
                      rules: [_vm.requiredRules()],
                      required: "",
                      outlined: "",
                      rounded: "",
                      dense: "",
                      type: "text",
                      disabled: !!_vm.userData.fidelity
                    },
                    model: {
                      value: _vm.userData.firstName,
                      callback: function($$v) {
                        _vm.$set(_vm.userData, "firstName", $$v)
                      },
                      expression: "userData.firstName"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      label: `${_vm.$t("profile.address.address")} *`,
                      rules: [_vm.requiredRules()],
                      required: "",
                      outlined: "",
                      rounded: "",
                      dense: "",
                      type: "text"
                    },
                    model: {
                      value: _vm.userData.address,
                      callback: function($$v) {
                        _vm.$set(_vm.userData, "address", $$v)
                      },
                      expression: "userData.address"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      label: `${_vm.$t("profile.address.addressNumber")} *`,
                      rules: [_vm.requiredRules()],
                      required: "",
                      outlined: "",
                      rounded: "",
                      dense: "",
                      type: "text"
                    },
                    model: {
                      value: _vm.userData.addressNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.userData, "addressNumber", $$v)
                      },
                      expression: "userData.addressNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("v-text-field", {
                    ref: "cap",
                    staticClass: "mt-2",
                    attrs: {
                      label: `${_vm.$t("profile.address.postalcode")} *`,
                      rules: _vm.capRules,
                      required: "",
                      outlined: "",
                      rounded: "",
                      dense: "",
                      type: "text"
                    },
                    on: { input: _vm.getCities },
                    model: {
                      value: _vm.userData.cap,
                      callback: function($$v) {
                        _vm.$set(_vm.userData, "cap", $$v)
                      },
                      expression: "userData.cap"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _vm.hasData
                    ? _c("v-text-field", {
                        staticClass: "mt-2",
                        attrs: {
                          label: `${_vm.$t("profile.address.city")} *`,
                          rules: [_vm.requiredRules()],
                          required: "",
                          outlined: "",
                          rounded: "",
                          dense: "",
                          type: "text",
                          disabled: "",
                          loading: _vm.loadingCities
                        },
                        model: {
                          value: _vm.userData.city,
                          callback: function($$v) {
                            _vm.$set(_vm.userData, "city", $$v)
                          },
                          expression: "userData.city"
                        }
                      })
                    : _c("v-select", {
                        ref: "selectedCity",
                        staticClass: "mt-2",
                        attrs: {
                          items: _vm.cityList,
                          "item-text": "city",
                          "return-object": "",
                          label: `${_vm.$t("profile.address.city")} *`,
                          outlined: "",
                          rounded: "",
                          dense: "",
                          disabled: _vm.isFieldDisabled || !_vm.showCityInput,
                          rules: [_vm.requiredRules()],
                          "no-data-text": _vm.$t(
                            "profile.address.noCityFoundForCap"
                          ),
                          loading: _vm.loadingCities
                        },
                        on: { change: _vm.citySelected },
                        model: {
                          value: _vm.selectedCity,
                          callback: function($$v) {
                            _vm.selectedCity = $$v
                          },
                          expression: "selectedCity"
                        }
                      })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("v-text-field", {
                    ref: "province",
                    staticClass: "mt-2",
                    attrs: {
                      label: `${_vm.$t("profile.address.province")} *`,
                      rules: [_vm.requiredRules()],
                      required: "",
                      outlined: "",
                      rounded: "",
                      dense: "",
                      type: "text",
                      disabled: ""
                    },
                    model: {
                      value: _vm.userData.prov,
                      callback: function($$v) {
                        _vm.$set(_vm.userData, "prov", $$v)
                      },
                      expression: "userData.prov"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      rules: _vm.phoneRules,
                      label: `${_vm.$t("profile.contacts.mobilePhone")} *`,
                      outlined: "",
                      rounded: "",
                      dense: "",
                      disabled: _vm.isFieldDisabled
                    },
                    model: {
                      value: _vm.userData.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.userData, "phone", $$v)
                      },
                      expression: "userData.phone"
                    }
                  })
                ],
                1
              ),
              _c("v-col", { staticClass: "my-3", attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "text-uppercase font-weight-semibold" },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("profile.header.loginData")) + " "
                    )
                  ]
                )
              ]),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      label: `${_vm.$t("profile.email.newEmail")} *`,
                      rules: _vm.emailRules,
                      required: "",
                      outlined: "",
                      rounded: "",
                      dense: ""
                    },
                    model: {
                      value: _vm.userData.email,
                      callback: function($$v) {
                        _vm.$set(_vm.userData, "email", $$v)
                      },
                      expression: "userData.email"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      label: `${_vm.$t("profile.email.emailConfirm")}`,
                      hint: _vm.$t(
                        "register.card.step-0.placeholder.emailHint"
                      ),
                      "persistent-hint": "",
                      rules: _vm.emailConfirmRules,
                      outlined: "",
                      rounded: "",
                      dense: ""
                    },
                    on: {
                      paste: function($event) {
                        $event.preventDefault()
                      }
                    },
                    model: {
                      value: _vm.emailConfirm,
                      callback: function($$v) {
                        _vm.emailConfirm = $$v
                      },
                      expression: "emailConfirm"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      label: `${_vm.$t("profile.password")} *`,
                      type: _vm.showPassword ? "text" : "password",
                      rules: _vm.passwordRules,
                      required: "",
                      outlined: "",
                      dense: "",
                      rounded: ""
                    },
                    model: {
                      value: _vm.userData.password,
                      callback: function($$v) {
                        _vm.$set(_vm.userData, "password", $$v)
                      },
                      expression: "userData.password"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      label: `${_vm.$t("profile.passwordConfirm")} *`,
                      type: _vm.showPassword ? "text" : "password",
                      rules: _vm.passwordConfirmRules,
                      required: "",
                      outlined: "",
                      dense: "",
                      rounded: ""
                    },
                    on: {
                      paste: function($event) {
                        $event.preventDefault()
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "my-3", attrs: { cols: "12" } },
                [
                  _c("h5", { staticClass: "text-uppercase" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("profile.disclaimerTitle")) + " "
                    )
                  ]),
                  _vm.userData.fidelity
                    ? _c("i18n", {
                        staticClass: "pre-wrap mt-2 text-body-2",
                        attrs: { path: "profile.disclaimerSubtitle", tag: "p" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "link1",
                              fn: function() {
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-decoration-underline",
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.handleRedirect(
                                            "https://www.galasupermercati.it/privacy"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "profile.disclaimerSubtitleLink"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "link2",
                              fn: function() {
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-decoration-underline",
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.handleRedirect(
                                            "https://www.galasupermercati.it/informativa-carta-fedelta"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "profile.disclaimerSubtitleLink2"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          2941819514
                        )
                      })
                    : _c("v-checkbox", {
                        staticClass: "disclaimer-checkbox",
                        attrs: { rules: _vm.checkboxRules },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function() {
                              return [
                                _c("i18n", {
                                  staticClass: "pre-wrap mt-2 text-body-2",
                                  attrs: {
                                    path: "profile.disclaimerMain",
                                    tag: "span"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "link1",
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-decoration-underline",
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  $event.stopPropagation()
                                                  return _vm.handleRedirect(
                                                    "https://www.galasupermercati.it/informativa-carta-fedelta"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "profile.disclaimerMainLink"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "link2",
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-decoration-underline",
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  $event.stopPropagation()
                                                  return _vm.handleRedirect(
                                                    "https://www.galasupermercati.it/regolamento-carta-fedelta"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "profile.disclaimerMainLink2"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                })
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.disclaimerMain,
                          callback: function($$v) {
                            _vm.disclaimerMain = $$v
                          },
                          expression: "disclaimerMain"
                        }
                      })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }