<template>
  <v-card
    rounded="md"
    :href="link"
    outlined
    @click.prevent="handleLink"
    class="proposal-btn"
    :style="cardStyle"
  >
    <div
      :style="getIconSectionStyle()"
      class="img-container rounded-md"
      :class="
        $ebsn.meta(proposal, 'metaData.category_proposal_type.INVERTED', false)
          ? 'order-2'
          : 'order-1'
      "
    >
      <embed
        v-if="proposal.img"
        :src="proposal.img"
        :alt="proposal.imgAlt"
        :title="proposal.imgDescription"
        :style="`max-width: 100%`"
        class="img-embedded"
      />
    </div>
    <div
      class="btn-label"
      v-if="proposal.name"
      v-html="proposal.name"
      :class="
        $ebsn.meta(proposal, 'metaData.category_proposal_type.INVERTED', false)
          ? 'order-1'
          : 'order-2'
      "
    ></div>
  </v-card>
</template>
<style lang="scss">
svg {
  fill: inherit;
}
path {
  fill: inherit;
}
.proposal-btn {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  .img-container {
    svg {
      fill: inherit;
    }
    path {
      fill: inherit;
    }
    width: 100%;
    text-align: center;
    padding: 8px;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    position: relative;
    img {
      width: 54px;
      height: "auto";
      max-height: 100%;
    }
  }
  .btn-label {
    padding: 8px;
    overflow: hidden;
    font-weight: 700;
    letter-spacing: 0;
    font-size: 1rem;
    max-width: 100%;
    text-align: center;
    @media (max-width: 320px) {
      white-space: normal;
      text-align: center;
    }
  }
}
</style>
<script>
import banner from "~/mixins/banner";

export default {
  name: "ProposalButton",
  props: {
    proposal: { type: Object, required: true }
  },
  mixins: [banner],
  computed: {
    cardStyle() {
      let style = {};
      let backgroundColor = this.$ebsn.meta(
        this.proposal,
        "metaData.category_proposal_type.BG_COLOR",
        "var(--v-white-base)"
      );
      if (backgroundColor) {
        style.backgroundColor = backgroundColor;
      }
      return style;
    }
  },
  methods: {
    getIconSectionStyle() {
      let style = {};
      let backgroundColor = this.$ebsn.meta(
        this.proposal,
        "metaData.category_proposal_type.BG_ICON_COLOR"
      );
      if (backgroundColor) {
        style.backgroundColor = backgroundColor;
        style.borderTopColor = backgroundColor;
      }
      return style;
    }
  }
};
</script>
