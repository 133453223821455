<template>
  <v-container fluid class="d-flex flex-column pb-0 pt-12 px-0">
    <div
      class="d-flex flex-column align-center justify-space-between w-100 pa-3"
    >
      <img src="/logo/logo.png" alt="Logo" :style="{ height: '70px' }" />
      <h2 class="default--text" v-html="$t('resetPassword.title')"></h2>
      <p class="text-center" v-html="$t('resetPassword.subtitle')"></p>
    </div>
    <v-card class="elevation-0 px-4">
      <v-row no-gutters class="mt-4">
        <v-col cols="12">
          <v-card-text class="pa-0">
            <v-form v-on:submit.prevent ref="passwordForm" v-model="valid">
              <v-text-field
                v-model="email"
                label="Email *"
                required
                outlined
                dense
                rounded
                clearable
                :rules="emailRules"
                @keyup.enter="handleResetPasswordRequest"
              ></v-text-field>
            </v-form>
          </v-card-text>
        </v-col>
        <v-col cols="12" class="px-0 mb-sm-4">
          <v-card-actions class="pa-0">
            <v-btn
              @click="handleResetPasswordRequest"
              class="reset-button"
              color="primary"
              large
              rounded
              block
              depressed
              :loading="loading"
              >{{ $t("resetPassword.submit") }}</v-btn
            >
          </v-card-actions>
        </v-col>
      </v-row>
      <div class="mt-2">
        <ResponseMessage :response="response" />
      </div>
    </v-card>
  </v-container>
</template>
<style lang="scss"></style>
<script>
import AbbondanzaRegistrationService from "@/service/abbondanzaRegistrationService";
import ResponseMessage from "@/components/ResponseMessage.vue";
import { requiredValue, isEmail } from "~/validator/validationRules";
import category from "~/mixins/category";

export default {
  components: { ResponseMessage },
  name: "ResetPasswordRequest",
  mixins: [category],
  data() {
    return {
      email: "",
      valid: true,
      loading: false,
      lazy: true,
      requiredRules: [requiredValue("Digitare l'email")],
      emailRules: [requiredValue("Digitare l'email"), isEmail()],
      response: {}
    };
  },
  methods: {
    async handleResetPasswordRequest(e) {
      e.preventDefault();
      this.response = {};
      let form = this.$refs.passwordForm.validate();
      if (form) {
        this.loading = true;
        let res = null;
        try {
          res = await AbbondanzaRegistrationService.recoverPassword(this.email);
          this.response = res.response;
        } catch (err) {
          console.log(err);
        } finally {
          this.loading = false;
        }
      }
    },
    resetPage() {
      this.email = "";
      this.response = {};
      this.showForm = true;
    }
  }
};
</script>
