import join from "lodash/join";
import isNil from "lodash/isNil";

export default {
  unitMultiplier: {
    g: 1000,
    gr: 1000,
    kg: 1,
    hg: 10,
    dag: 100,
    ml: 1000,
    cl: 100,
    lt: 1,
    l: 1
  },
  getMultiplier(product) {
    let weightUnitSelling = (
      product.productInfos.WEIGHT_UNIT_SELLING || "kg"
    ).toLowerCase();
    let weightBaseUnit = (
      product.productInfos.WEIGHT_UNIT_BASE || "kg"
    ).toLowerCase();

    return (
      this.unitMultiplier[weightUnitSelling] /
      this.unitMultiplier[weightBaseUnit]
    );
  },
  getPricePerUnit(price, product) {
    if (product.productInfos.WEIGHT_SELLING) {
      return (
        (price * this.getMultiplier(product)) /
        parseFloat(product.productInfos.WEIGHT_SELLING)
      );
    } else return 0;
  },
  encodeProductQuantity(product, quantity) {
    return product.productInfos.TIPOLOGIA === "Sfuso"
      ? quantity / this.getMultiplier(product)
      : quantity;
  },
  decodeProductQuantity(product, quantity) {
    return product.productInfos.TIPOLOGIA === "Sfuso"
      ? quantity * this.getMultiplier(product)
      : quantity;
  },
  getProductFullnameForToast(product) {
    return join([product.name, product.shortDescr, product.descr], " ");
  },
  getProductQuantityForToast(item) {
    if (isNil(item)) return 0;
    if (item.product.productInfos.TIPOLOGIA == "Sfuso") {
      //Handling floating point decimals issues
      var val = (item.weight * 100).toFixed(0);
      val = val * this.getMultiplier(item.product);
      val = val / 100;
      return val;
    } else {
      return item.quantity;
    }
  },
  getProductUnitForToast(product) {
    if (product.productInfos) {
      if (product.productInfos.TIPOLOGIA == "Sfuso") {
        return product.productInfos.WEIGHT_UNIT_SELLING;
      } else {
        return product.productInfos.UNIT_SELLING != null
          ? product.productInfos.UNIT_SELLING
          : "pz";
      }
    }
  },
  getSellingSuffix(product, quantity) {
    var suffix = "";
    if (!product) {
      return;
    }
    if (product.productInfos.TIPOLOGIA == "Sfuso") {
      suffix = product.productInfos.WEIGHT_UNIT_SELLING;
      if (quantity && product.productInfos.WEIGHT_SELLING_SINGLE_UNIT) {
        try {
          var singleUnit =
            quantity /
            parseFloat(product.productInfos.WEIGHT_SELLING_SINGLE_UNIT);
          var singleUnitUm = product.productInfos.WEIGHT_SELLING_SINGLE_UNIT_UM
            ? product.productInfos.WEIGHT_SELLING_SINGLE_UNIT_UM
            : "pz";
          suffix = suffix + " (" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e, "getSellingSuffix error", {
            component: "ebsn.service",
            method: "getSellingSuffix"
          });
        }
      }
    } else if (product.productInfos.TIPOLOGIA == "Pezzo") {
      if (product.productInfos.UNIT_SELLING) {
        suffix = product.productInfos.UNIT_SELLING;
      } else {
        suffix = "pz";
      }
    } else if (product.productInfos.TIPOLOGIA == "Confezione") {
      if (product.productInfos.UNIT_SELLING) {
        suffix = product.productInfos.UNIT_SELLING;
      } else {
        suffix = "pz";
      }
    }
    return suffix;
  },
  getItemPerUnitSuffix(item, quantity) {
    var suffix = "";
    if (!item || !quantity) {
      return suffix;
    }

    var product = item.product;
    var customWeight;
    if (item.cartItemInfo && item.cartItemInfo.custom_weight) {
      customWeight = parseFloat(item.cartItemInfo.custom_weight);
    }
    return this.getPerUnitSuffix(product, quantity, customWeight);
  },
  getPerUnitSuffix(product, quantity, customWeight) {
    var suffix = "";
    if (!product || !quantity) {
      return suffix;
    }

    if (
      product.productInfos.TIPOLOGIA == "Sfuso" &&
      product.productInfos.WEIGHT_SELLING_SINGLE_UNIT &&
      parseFloat(product.productInfos.WEIGHT_SELLING_SINGLE_UNIT)
    ) {
      let unitSelling = customWeight
        ? customWeight
        : parseFloat(product.productInfos.WEIGHT_SELLING_SINGLE_UNIT);
      let singleUnit = Math.floor(quantity / unitSelling);
      let singleUnitUm = product.productInfos.WEIGHT_SELLING_SINGLE_UNIT_UM
        ? product.productInfos.WEIGHT_SELLING_SINGLE_UNIT_UM
        : "pz";
      suffix = singleUnit + " " + singleUnitUm.substring(0, 4);
    } else if (product.productInfos.TIPOLOGIA == "Confezione") {
      let unitSelling = customWeight
        ? customWeight
        : parseFloat(product.productInfos.WEIGHT_SELLING);
      let singleUnit = quantity * unitSelling;
      singleUnit = (singleUnit * 1000).toFixed(0);
      singleUnit = singleUnit / 1000;
      let singleUnitUm = product.productInfos.WEIGHT_UNIT_SELLING
        ? product.productInfos.WEIGHT_UNIT_SELLING
        : "gr";
      suffix =
        (singleUnit + " ").replace(".", ",") + singleUnitUm.substring(0, 4);
    }

    return suffix;
  },
  encodeItemQuantity(item, quantity, weight) {
    var q =
      typeof quantity != "undefined" && quantity != null
        ? quantity
        : item.quantity;
    var w =
      typeof weight != "undefined" && weight != null ? weight : item.weight;
    quantity = this.encodeProductQuantity(item.product, q, w);
    return quantity;
  }
};
