<template>
  <div class="subscribe-block-wrap" :style="bgImageStyle">
    <div v-html="description"></div>
    <!-- <div class="text-center text-body-0 mb-3">
      {{ $t("subscribeBlock.message") }}
    </div>
    <div class="my-5 d-flex justify-space-around align-center">
      <div class="d-flex flex-column justify-space-around">
        <img src="/img_layout/ottieni-punti.svg" height="30" class="py-1" />
        <div>{{ $t("subscribeBlock.getPoints") }}</div>
      </div>
      <div class="d-flex flex-column justify-space-around">
        <img src="/img_layout/dona-punti.svg" height="30" />
        <div>{{ $t("subscribeBlock.donatePoints") }}</div>
      </div>
      <div class="d-flex flex-column justify-space-around">
        <img src="/img_layout/sconti-per-te.svg" height="30" />
        <div>{{ $t("subscribeBlock.discountsForYou") }}</div>
      </div>
    </div> -->
    <div class="pb-2">
      <LoginBtn :label="buttonLabel" />
    </div>
  </div>
</template>
<style lang="scss">
.subscribe-block-wrap {
  background-color: $primary;
  border-radius: 8px;
  color: $white;
  padding: 12px 10px 5px;
  h2 {
    color: inherit;
  }
}
</style>
<script>
//import login from "~/mixins/login";
import get from "lodash/get";
import has from "lodash/has";
import LoginBtn from "../buttons/LoginBtn.vue";

export default {
  name: "SubscribeBlock",
  components: {
    LoginBtn
  },
  props: {
    config: {
      type: Object,
      required: true
    },
    container: {
      type: Boolean,
      default: true
    }
  },
  //mixins: [login],
  data() {
    return {};
  },
  computed: {
    description() {
      return get(
        this.config,
        "metaData.categoryblocktype_SubscribeBlock.DESCRIPTION"
      );
    },
    bgImageStyle() {
      return has(
        this.config,
        "metaData.categoryblocktype_SubscribeBlock.IMG_URL"
      )
        ? {
            backgroundImage:
              "url(" +
              this.config.metaData.categoryblocktype_SubscribeBlock.IMG_URL +
              ")",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat"
          }
        : {};
    },
    buttonLabel() {
      return get(
        this.config,
        "metaData.categoryblocktype_SubscribeBlock.BUTTON_TEXT",
        this.$t("subscribeBlock.button")
      );
    }
  }
};
</script>
