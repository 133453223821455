export default {
  computed: {},
  methods: {
    getOneSignalUUID() {
      return new Promise((resolve, reject) => {
        let trialCount = 0;
        console.log("One Signal getOneSignalUUID fucntion start");
        const checkUUID = () => {
          console.log(
            "One Signal getOneSignalUUID Trials counter = ",
            trialCount
          );
          if (trialCount >= 5) {
            reject(new Error("Exceeded maximum trials"));
            return;
          }

          if (window.plugins && window.plugins?.OneSignal) {
            const uuid = window.plugins.OneSignal.User.pushSubscription._id;
            if (uuid) {
              resolve(uuid);
            } else {
              trialCount++;
              setTimeout(checkUUID, 1000); // Check every second until UUID is available
            }
          } else {
            resolve(null);
          }
        };

        checkUUID();
      });
    }
  }
};
